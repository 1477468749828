<template>
  <div>
    <LoadingComponent v-if="isMounting || isExecutingNavigateToLocation" />
    <main class="underlayer-main">
      <h1>Portas新規会員登録 Portas会員限定 お得なサービスのご案内</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>Portas会員限定 お得なサービスのご案内</li>
      </ul>

      <ul class="application-flow grid pc-grid5 sp-grid5 gap10">
        <li>お申し込み受付</li>
        <li>お客様情報入力</li>
        <li class="stay">Portas会員限定 お得なサービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>

      <div class="my-service-margin-top">
        <p>Portas会員登録をされる方へ、お得なサービスをご案内しています。</p>
        <p>是非、この機会にお申し込みください。</p>
      </div>

      <div class="blc my-service-margin-top">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portasサービス選択" />Portas会員限定 お得なサービスのご案内（お申し込みは任意）</h2>
        <div class="my-service-image-outer">
          <div class="my-service-image">
            <img class="sp has-drop-shadow" src="../../../images/portas-subscription/service-eye-catch-sp.png" />
            <img class="pc has-drop-shadow" src="../../../images/portas-subscription/service-eye-catch-pc.png" />
          </div>
        </div>
      </div>

      <section class="my-portas-service">
        <h2 class="underlayer-h2 my-portas-service__title">あんしんサポートセットプラス<span class="price-next-to-title">月額1,496円（税込）</span></h2>

        <div class="my-portas-service__description">
          <h3 class="description-title">セット内容</h3>
          <p class="service-name">お申し込みセットサービス: ノートン&trade; セキュリティ + Internet SagiWall for マルチデバイス + Wi-Fi&Device Support + AdGuard</p>

          <div class="my-portas-service__description-detail">
            <div class="my-service-image-outer">
              <div class="my-service-image">
                <img class="sp" src="../../../images/portas-subscription/service-description-sp.png" />
                <img class="pc" src="../../../images/portas-subscription/service-description-pc.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="my-portas-service__terms-area">
          <p>
            <a
              class="link"
              href="https://www.arteria-net.com/portas/service/setplus?utm_source=Portas&utm_medium=about&utm_campaign=setplus&utm_content=confirmation_portop"
              target="_blank"
              >サービス詳細→</a
            >
          </p>
          <p><a class="link" href="https://www.arteria-net.com/portas/service/terms/marge/setplus.pdf" target="_blank">ご利用規約→</a></p>
        </div>
      </section>

      <div class="blc">
        <div class="portas-service-attention">
          <p>以下Portasサービスの初回申し込みの無料期間を、2024年10月31日(木)をもちまして、3カ月間から2カ月間に変更させていただくことになりました。</p>
          <p>・ノートン™ セキュリティ</p>
          <p>・Internet SagiWall for マルチデバイス</p>
          <p>・Wi-Fi&Device Support</p>
          <p>2024年10月31日（木）以前のお申し込みにつきましては、お申し込み月を含め、3ヵ月間の無料期間が適用されます。</p>
          <p>2024年11月1日（金）以降のお申し込みにつきましては、お申し込み月を含め、２ヵ月間の無料期間が適用されます。</p>
          <a :href="portasServiceFreePeriodChangeNoticeUrl" target="_blank" class="red link"><b>【重要】Portasサービスの無料期間変更のお知らせ</b></a>
          <p>AdGuardにつきましては、初回申し込みの無料期間2ヵ月間から変更はございません。</p>
          <p>無料期間終了後は自動的にご利用料金が発生いたします。</p>
          <p class="red">お申し込み後のキャンセル・返金はできません。</p>
          <p>Portas(ポルタス)ご登録後の「Portasマイページ」から、セット内サービスをいつでもご解約いただけます。</p>
        </div>

        <div class="portas-service-attention">
          <p style="margin: 0; font-weight: bold">
            Portas会員登録に併せて、あんしんサポートセットプラスの申し込み手続きを行う方は、チェックボックスにチェックして「次へ」を押してください。
          </p>
        </div>

        <div class="my-portas-service__checkbox">
          <div>
            <div>
              <label> <input type="checkbox" v-model="isApplyForPortasService" />Portas会員登録に併せて、あんしんサポートセットプラスの申し込み手続きに移る</label>
            </div>
          </div>
        </div>

        <div class="notorder">
          <h4>あんしんサポートセットプラスが不要な方は、チェックを入れずに、そのまま「次へ」を押してください。</h4>
        </div>

        <div class="btn-area my-portas-service__button-wrapper">
          <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" @click="onNext()">次へ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-service-image-outer {
  padding-top: 24px;
}

.my-service-margin-top {
  margin-top: 32px;
}

.notorder {
  text-align: center;
  margin-top: 5px;
  margin-left: 20px;
}

.my-service-image {
  text-align: center;

  & img {
    width: 100%;
    margin: 0 auto;

    &.has-drop-shadow {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    &.sp {
      display: block;
      max-width: 360px;
    }

    &.pc {
      display: none;
      max-width: 960px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .my-service-image-outer {
    padding-top: 16px;
  }

  .my-service-image {
    & img {
      &.sp {
        display: none;
      }

      &.pc {
        display: block;
      }
    }
  }
}

.price-next-to-title {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}

.my-portas-service {
  margin-top: 64px;

  &__title {
    font-size: 16px;
  }

  &__checkbox {
    padding-top: 40px;

    & div {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      & div {
        position: relative;

        & label {
          font-size: 14px;
          padding-left: 40px;

          & input {
            position: absolute;
            top: 4px;
            left: 16px;
            transform: scale(1.5);
          }
        }
      }
    }
  }

  &__description {
    padding: 0 16px;

    & h3.description-title {
      font-size: 13px;
    }

    & p.service-name {
      font-size: 13px;
    }
  }

  &__description-detail {
    & h4.product-name {
      font-size: 13px;
    }

    & p.product-description {
      line-height: 1.6;
      letter-spacing: 0.8px;
    }
  }

  &__terms-area {
    margin-top: 8px;
    padding-left: 16px;
  }

  &__button-wrapper {
    margin-top: 0;
    padding-top: 40px;
  }
}

.portas-service-attention {
  margin-top: 32px;
}

@media only screen and (min-width: 768px) {
  .my-portas-service {
    &__title {
      font-size: 20px;
    }

    &__description {
      & h3.description-title {
        margin-bottom: 4px;
      }
    }

    &__description-detail {
      margin-top: 16px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .my-portas-service {
    &__checkbox {
      & div {
        & div {
          & label {
            font-size: 16px;

            & input {
              top: 5px;
            }
          }
        }
      }
    }
  }
}

button[disabled] {
  cursor: not-allowed;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { PRODUCT_ID_WITH_SPECIFIC_USE } from '@/shared/const/portas-subscription/product-id-with-specific-use';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'platform-entry-select-portas-service',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isMounting: true,
    isApplyForPortasService: false,
    isExecutingNavigateToLocation: false,
    member: null as Member | null,
    errorMessages: [] as string[],
    entryInputForm: null as PlatformEntryInputForm | null,
    portasServiceFreePeriodChangeNoticeUrl: process.env.VUE_APP_PORTAS_SERVICE_FREE_PERIOD_CHANGE_NOTICE_URL,
  }),
  mounted() {
    // /platform/entry/confirm から戻ってきたときの処理
    this.entryInputForm = this.$store.getters['platformEntryStore/entryInputForm'];
    if (this.entryInputForm) {
      if (this.entryInputForm.wishToContractProductIdList && this.entryInputForm.wishToContractProductIdList.length > 0) {
        this.isApplyForPortasService = true;
      }
      this.entryInputForm.wishToContractProductIdList = [];
    } else {
      this.$router.push('/platform/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    this.member = this.$store.getters['memberStore/member'];
    this.isMounting = false;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      // エラーメッセージを初期化
      this.errorMessages = [];

      if (this.isApplyForPortasService) {
        const entryInputForm: PlatformEntryInputForm | null = this.$store.getters['platformEntryStore/entryInputForm'];
        if (!entryInputForm) {
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
        } else if (!entryInputForm.address || !entryInputForm.zipcode1 || !entryInputForm.zipcode2) {
          // エラーメッセージをストアに格納
          this.errorMessages.push('Portasサービスのお申し込みには郵便番号・住所のご登録が必要です。Portasサービス契約住所を入力してください。');
          this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);

          // 遷移元画面にリダイレクト
          await this.$router.push('/platform/entry/input').catch((e) => {
            checkRouterError(e);
          });

          this.isExecutingNavigateToLocation = false;
          return;
        }

        entryInputForm.wishToContractProductIdList = PRODUCT_ID_WITH_SPECIFIC_USE.USE_IN_PORTAS_ENTRY;
        this.$store.commit('platformEntryStore/entryInputForm', entryInputForm);
      }

      await this.$router.push('/platform/entry/confirm').catch((e) => {
        checkRouterError(e);
      });
      this.isExecutingNavigateToLocation = false;
    },
  },
});
</script>
