export const PLATFORM_SWIPER_IMAGE_LIST = [
  {
    isConnectix: false,
    pc: require('../../images/notlogin/slide01.png'),
    sp: require('../../images/notlogin/slide01-sp.png'),
  },
  {
    isConnectix: true,
    pc: require('../../images/slide01.jpg'),
    sp: require('../../images/slide01-sp.jpg'),
  },
];

export const PLATFORM_CONNECTIX_IMAGE = {
  pc: require('../../images/slide01.jpg'),
  sp: require('../../images/slide01-sp.jpg'),
};

export const PLATFORM_MAIN_IMAGE = {
  pc: require('../../images/notlogin/slide01.png'),
  sp: require('../../images/notlogin/slide01-sp.png'),
};

export const PLATFORM_UNEXT_IMAGE = {
  sp: require('../../images/swiper/bnr_unextforportas_SP20240704.png'),
  pc: require('../../images/swiper/bnr_unextforportas_PC20240704.png'),
  url: `https://www.arteria-net.com/portas/service/u-next?utm_source=Portas&utm_medium=banner&utm_campaign=u-next&utm_content=slide_after_portop`,
  name: 'unext',
};

export const PLATFORM_UNEXT_IMAGE_BEFORE_LOGIN = {
  sp: require('../../images/swiper/bnr_unextforportas_be_SP20240704.png'),
  pc: require('../../images/swiper/bnr_unextforportas_be_PC20240704.png'),
  url: `https://www.arteria-net.com/portas/service/u-next?utm_source=Portas&utm_medium=banner&utm_campaign=u-next&utm_content=slide_before_portop`,
  name: 'unext',
};

export const TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_GOODS = [
  {
    scrollTo: { element: '#goods', offset: 0 },
    pc: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-pc.png'),
    sp: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-sp.png'),
  },
];

export const TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_CAMPAIGN = [
  {
    scrollTo: { element: '#campaign', offset: -30 },
    pc: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-pc.png'),
    sp: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-sp.png'),
  },
];

export const TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_INFORMATION = [
  {
    scrollTo: { element: '#information', offset: -30 },
    pc: require('../../images/swiper/dummy-tsunagu-online-shop-information-pc.png'),
    sp: require('../../images/swiper/dummy-tsunagu-online-shop-information-sp.png'),
  },
];

export const TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_LIST = [
  {
    scrollTo: '#vue-tsunagu-online-shop',
    pc: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-pc.png'),
    sp: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-sp.png'),
  },
  {
    scrollTo: '#vue-tsunagu-online-shop',
    pc: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-pc.png'),
    sp: require('../../images/swiper/dummy-tsunagu-online-shop-scroll-to-sp.png'),
  },
];

export const TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_PLATFORM = [
  {
    pc: require('../../images/swiper/tsunag-online-shop-logged-in-pc-2023-09-20.png'),
    sp: require('../../images/swiper/tsunag-online-shop-logged-in-sp-2023-09-20.png'),
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1Y1W042308YW.html`,
  },
];

export const TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_NOT_LOGGED_IN = [
  {
    pc: require('../../images/swiper/tsunag-online-shop-not-logged-in-pc-2023-09-20.png'),
    sp: require('../../images/swiper/tsunag-online-shop-not-logged-in-sp-2023-09-20.png'),
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1Y1W042308YW.html`,
  },
];
//Portasサービス(ログイン後)
export const PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_LOGGED_IN = [
  {
    pc: require('../../images/swiper/bnr_adguard_20240725_PC_after_login.gif'),
    sp: require('../../images/swiper/bnr_adguard_20240725_SP_after_login.gif'),
    url: `https://www.arteria-net.com/portas/service/adguard?utm_source=Portas&utm_medium=banner&utm_campaign=adguard&utm_content=slide_after_portop`,
    name: 'adguard',
  },
  {
    pc: require('../../images/swiper/norton_pc_after_login.png'),
    sp: require('../../images/swiper/norton_sp_after_login.png'),
    url: `https://portasservice.arteria-net.com/norton?utm_source=Portas&utm_medium=banner&utm_campaign=norton&utm_content=service_guide`,
    name: 'norton',
  },
  {
    pc: require('../../images/swiper/SagiWall_pc_after_login.png'),
    sp: require('../../images/swiper/SagiWall_sp_after_login.png'),
    url: `https://portasservice.arteria-net.com/sagiwall?utm_source=Portas&utm_medium=banner&utm_campaign=sagiwall&utm_content=service_guide`,
    name: 'sagi',
  },
  {
    pc: require('../../images/swiper/Support_pc.jpg'),
    sp: require('../../images/swiper/Support_sp.jpg'),
    url: `https://portasservice.arteria-net.com/dsup?utm_source=Portas&utm_medium=banner&utm_campaign=dsup&utm_content=service_guide`,
    name: 'dsup',
  },
];
//Portasサービス(ログイン前)
export const PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_NOT_LOGGED_IN = [
  {
    pc: require('../../images/swiper/bnr_adguard_20240725_PC_before_login.gif'),
    sp: require('../../images/swiper/bnr_adguard_20240725_SP_before_login.gif'),
    url: `https://www.arteria-net.com/portas/service/adguard?utm_source=Portas&utm_medium=banner&utm_campaign=adguard&utm_content=slide_before_portop`,
    name: 'adguard',
  },
  {
    pc: require('../../images/swiper/norton_pc_before_login.png'),
    sp: require('../../images/swiper/norton_sp_before_login.png'),
    url: `https://portasservice.arteria-net.com/norton?utm_source=Portas&utm_medium=banner&utm_campaign=norton&utm_content=service_guide`,
    name: 'norton',
  },
  {
    pc: require('../../images/swiper/SagiWall_pc_before_login.png'),
    sp: require('../../images/swiper/SagiWall_sp_before_login.png'),
    url: `https://portasservice.arteria-net.com/sagiwall?utm_source=Portas&utm_medium=banner&utm_campaign=sagiwall&utm_content=service_guide`,
    name: 'sagi',
  },
  {
    pc: require('../../images/swiper/Support_pc.jpg'),
    sp: require('../../images/swiper/Support_sp.jpg'),
    url: `https://portasservice.arteria-net.com/dsup?utm_source=Portas&utm_medium=banner&utm_campaign=dsup&utm_content=service_guide`,
    name: 'dsup',
  },
];
//あんしんサポートセットプラス
export const PORTAS_SERVICE_IMAGE_SETPLUS_LOGGED_IN = {
  pc: require('../../images/swiper/bnr_setplus_PC_after_login.png'),
  sp: require('../../images/swiper/bnr_setplus_SP_after_login.png'),
  url: `https://www.arteria-net.com/portas/service/setplus?utm_source=Portas&utm_medium=banner&utm_campaign=setplus&utm_content=slide_after_portop`,
  name: 'setplus',
};
export const PORTAS_SERVICE_IMAGE_SETPLUS_NOT_LOGGED_IN = {
  pc: require('../../images/swiper/bnr_setplus_PC_before_login.png'),
  sp: require('../../images/swiper/bnr_setplus_SP_before_login.png'),
  url: `https://www.arteria-net.com/portas/service/setplus?utm_source=Portas&utm_medium=banner&utm_campaign=setplus&utm_content=slide_before_portop`,
  name: 'setplus',
};

export const PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_NOT_LOGGED_IN = [
  {
    pc: require('../../images/campaign/mineo_pc.jpg'),
    sp: require('../../images/campaign/mineo_sp.jpg'),
    url: '',
  },
];

export const PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_LOGGED_IN = [
  {
    pc: require('../../images/campaign/mineo_pc.jpg'),
    sp: require('../../images/campaign/mineo_sp.jpg'),
    url: `${process.env.VUE_APP_MINEO_CAMPAIN_URL}`,
  },
];

export const TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_WIFI_CONNECTIX = [
  {
    pc: require('../../images/swiper/wifi6_pc.png'),
    sp: require('../../images/swiper/wifi6_sp.png'),
    url: `${process.env.VUE_APP_TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_WIFI_CONNECTIX_URL}`,
  },
];
