<template>
  <div>
    <LoadingComponent v-if="isMounting" />

    <!-- ISP退会後13ヶ月間の表示: ロゴとISP会員専用トップ -->
    <template v-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.LOGO_AND_LINK_TO_MEMBER_PAGE">
      <div class="blc">
        <!-- サブスク商品 Portas会員ステータスが退会申し込みのとき表示しない -->
        <template v-if="displayPortasSubscriptionPurchaseLead">
          <SubscriptionCommonComponent />
        </template>
      </div>

      <div class="blc">
        <div>
          <section class="my-portal-service">
            <h2 class="my-portal-service__title">インターネットサービス</h2>
            <div class="my-portal-service__image">
              <img src="../../../images/logo_fivea.png" alt="Five.A" />
            </div>
            <p class="my-portal-service__description">
              お住まいの建物には、Five.A インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいの建物専用サイトをご覧ください。
            </p>
            <a class="link" :href="getForBuildingUrl()" target="My Five.A">＞ お住まいの建物専用サイトはこちら</a>
          </section>
        </div>
      </div>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Five.A会員専用トップページ" />Five.A会員専用トップページ</h2>
        <p>Five.Aご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/5a" class="link">こちら</router-link></p>
      </div>

      <!-- つなぐオンラインショップ  -->
      <template v-if="displayTsunaguOnlineShop()">
        <div class="blc">
          <TsunaguOnlineShop
            @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceFiveA()"
            @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtFiveA()"
          />
        </div>
      </template>

      <!-- Mcloudを連携している場合 -->
      <template v-if="isLinkedToMcloud">
        <div class="blc">
          <McloudCommonComponent />
        </div>
      </template>

      <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
      <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
        <div class="blc">
          <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
        </div>
      </template>
    </template>

    <!-- ISP会員のときの表示: 通常表示 -->
    <template v-else-if="displayPortalPattern === DISPLAY_PORTAL_PATTERN.NORMAL">
      <div class="blc">
        <!-- サブスク商品ブロック -->
        <SubscriptionCommonComponent />
      </div>

      <div class="blc">
        <div>
          <section class="my-portal-service">
            <h2 class="my-portal-service__title">インターネットサービス</h2>
            <div class="my-portal-service__image">
              <img src="../../../images/logo_fivea.png" alt="Five.A" />
            </div>
            <p class="my-portal-service__description">
              お住まいの建物には、Five.A インターネットサービスが提供されております。サービス内容やお問い合わせ先はお住まいの建物専用サイトをご覧ください。
            </p>
            <a class="link" :href="getForBuildingUrl()" target="My Five.A">＞ お住まいの建物専用サイトはこちら</a>
          </section>

          <!-- Five.Aからのお知らせ -->
          <div class="blc">
            <h2 class="portal-h2 cf">
              <img src="../../../images/service-h2.svg" alt="Five.Aからのお知らせ" />Five.Aからのお知らせ
              <a href="https://help.ucom.ne.jp/trouble/index.html" target="My Five.A" class="link"
                >広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a
              >
            </h2>

            <template v-if="emInfoMessages && emInfoMessages.length">
              <div class="service-news border-grey">
                <ul>
                  <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
                    <span class="noticelist">{{ getKindString(message.message_kind) }}</span>
                    <b>{{ message.message_date }} </b>
                    <span v-if="message.message_link">
                      <a :href="message.message_link" target="My Five.A" v-html="message.message_label"></a>
                    </span>
                    <span v-if="!message.message_link" v-html="message.message_label"></span>
                  </li>
                </ul>
              </div>
            </template>
          </div>

          <!-- Five.A会員専用トップページ -->
          <template v-if="isDiaplySectionLeadTo5a()">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Five.A会員専用トップページ" />Five.A会員専用トップページ</h2>
              <p>Five.Aご契約情報の登録変更やオプションサービスのお申し込み・変更は<router-link to="/5a" class="link">こちら</router-link></p>
            </div>
          </template>

          <!-- Connectixお申し込み: Connectix提供物件 かつ Connectix未契約 -->
          <template v-if="isProvidedConnectix && !isContractedConnectix && property">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectixお申し込み" />Connectixお申し込み</h2>
              <div class="table-scroll js-scrollable">
                <table class="table">
                  <tr>
                    <th colspan="2">サービス</th>
                    <th>料金</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td colspan="2">Connectix</td>

                    <!-- キャンペーン中のときは、表示を変更する -->
                    <td v-bind:class="{ 'is-during-campaign': property.op.sdwan.fee_cp }">
                      <span class="red">キャンペーン中</span>
                      <span class="default-fee">{{ property.op.sdwan.fee }}円/月</span>
                      <span class="red">→ {{ property.op.sdwan.fee_cp }}円／月</span>
                    </td>

                    <td>
                      <div class="connectix-link">
                        <!-- Five.A 未会員の場合 -->
                        <template v-if="member && !member.primaryKeyMye">
                          <router-link to="/5a/link-service">お申し込み</router-link>
                        </template>

                        <!-- Five.A 会員の場合 -->
                        <template v-if="member && member.primaryKeyMye">
                          <router-link to="/connectix/5a/terms">お申し込み</router-link>
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
                <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
                <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
              </div>
            </div>
          </template>

          <!-- インターネット通信速度にお困りの方へ: Connectix提供物件 かつ Connectix未契約 -->
          <template v-if="isProvidedConnectix && !isContractedConnectix">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
              <ul class="my-card-list grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://speedtest.tsunagunet.com/f/" target="My Five.A">
                        <img src="../../../images/ico01.png" alt="スピードテスト" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">自宅の通信環境をチェック</h4>
                      <p class="my-card-content__description">現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                      <p class="my-card-content__link-text">
                        <a href="https://speedtest.tsunagunet.com/f/" target="My Five.A" class="link">
                          スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i>
                        </a>
                      </p>
                    </div>
                  </section>
                </li>
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My Five.A">
                        <img src="../../../images/ico02.png" alt="通信の改善" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">接続速度が遅いと感じた場合</h4>
                      <p class="my-card-content__description">
                        モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                      </p>
                      <p class="my-card-content__link-text">
                        <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My Five.A" class="link">
                          改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                        </a>
                      </p>
                    </div>
                  </section>
                </li>
                <li class="my-card-list__item">
                  <section class="my-card-content">
                    <div class="my-card-content__image">
                      <a :href="connectixLpUrl" target="My Five.A">
                        <img src="../../../images/ico03.png" alt="オプション" class="mt20" />
                      </a>
                    </div>
                    <div class="my-card-content__text">
                      <h4 class="my-card-content__title">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</h4>
                      <p class="my-card-content__description">インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                      <p class="my-card-content__link-text">
                        <a :href="connectixLpUrl" target="My Five.A" class="link">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                      </p>
                    </div>
                  </section>
                </li>
              </ul>
            </div>
          </template>

          <!-- つなぐオンラインショップ  -->
          <template v-if="displayTsunaguOnlineShop()">
            <div class="blc">
              <TsunaguOnlineShop
                @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPortalServiceFiveA()"
                @updateDisplayPortalPatternFromTsunaguOnlineShop="updateDisplayPortalPatternAtFiveA()"
              />
            </div>
          </template>

          <!-- Mcloudを連携している場合 -->
          <template v-if="isLinkedToMcloud">
            <div class="blc">
              <McloudCommonComponent />
            </div>
          </template>

          <!-- Mcloudを連携していないかつportas退会申し込み中でない場合 -->
          <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud">
            <div class="blc">
              <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
            </div>
          </template>
        </div>
      </div>
    </template>

    <template v-if="isDisplayFAQComponent()">
      <div class="blc">
        <FrequentlyAskedQuestions :serviceType="getServiceType()" :isDisplayConnectixFAQ="isDisplayConnectixFAQ" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.my-portal-service {
  &__title {
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
  }
  &__image {
    margin-bottom: 10px;
  }
  &__description {
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 0.8px;
  }
}

p.my-description {
  line-height: 1.6;
  letter-spacing: 0.8px;
}

ul.my-card-list {
  list-style: none;
  margin: 0;
  padding: 0;
  & li.my-card-list__item {
    border: 1px solid #d3d3d3;
    text-align: center;
  }
}

section.my-card-content {
  & div.my-card-content__image {
    & img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  & div.my-card-content__text {
    padding: 10px 30px 20px;
    text-align: left;
    & h4.my-card-content__title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 25px;
    }
    & p.my-card-content__description {
      line-height: 1.6;
      letter-spacing: 0.8;
    }
    & p.my-card-content__link-text {
      text-align: right;
    }
  }
}

.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
  & ul {
    padding: 0;
    & li {
      padding: 15px 0 15px 20px;
      border-bottom: 1px solid #cf1225;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        box-sizing: border-box;
        border: 6px solid transparent;
        border-left: 10px solid #cf1225;
      }
    }
  }
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

table.table {
  margin: 40px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
  & th {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    background-color: #fbfbfb;
  }
  & td {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    & span {
      display: none;
      &.default-fee {
        display: inline;
      }
    }
    &.is-during-campaign {
      & span {
        display: block;
      }
    }
    & div.connectix-link {
      display: flex;
      justify-content: center;
      & a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        color: #d11c2e;
        border: 1px solid #d11c2e;
        border-radius: 3px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .my-portal-service {
    &__title {
      font-size: 18px;
    }
  }

  .service-news {
    padding: 0 20px 15px;
  }

  section.my-card-content {
    & div.my-card-content__text {
      padding: 10px 15px 20px;
    }
  }

  div.table-scroll {
    & table {
      width: 780px;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { EmInfoMessage, FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import FrequentlyAskedQuestions from '../../components/frequently-asked-questions.vue';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import { DISPLAY_PORTAL_PATTERN } from '../../../shared/const/service-type';
import { getDisplayPortalPatternCaseFiveA } from '@/shared/util/func-get-display-portal-pattern-isp-withdrawal';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import McloudCommonComponent from '../../components/mcloud-common.vue';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import SubscriptionCommonComponent from './subscription-common.vue';

export default defineComponent({
  name: 'portal-service-five-a',
  components: {
    LoadingComponent,
    FrequentlyAskedQuestions,
    McloudCommonComponent,
    TsunaguOnlineShop,
    SubscriptionCommonComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    // ISP会員ステータス
    ispMemberStatus: string;
    // portal表示パターン
    displayPortalPattern: string;
    // Portalの表示パターン定数
    DISPLAY_PORTAL_PATTERN: typeof DISPLAY_PORTAL_PATTERN.FIVE_A;
    // Five.A物件基本情報
    property: FiveAProperty | null;
    // Five.A契約基本情報
    customer: FiveACustomer | null;
    // Connectix提供物件かどうか
    isProvidedConnectix: boolean;
    // Connectix契約済みかどうか
    isContractedConnectix: boolean;
    // Connectixに関するよくある質問を表示するかどうか
    isDisplayConnectixFAQ: boolean;
    // お知らせ用メッセージ
    emInfoMessages: Array<EmInfoMessage>;
    // ConnectixLP URL
    connectixLpUrl: string;
    // scroll-hint モジュール
    ScrollHint: any;
    // mounted() 実行中かどうか
    isMounting: boolean;
    // Mcloudと連携しているかどうか
    isLinkedToMcloud: boolean;
    // Mcloud未連携時にリンクを表示するかどうか
    isDisplayNotLinkedMcloud: boolean;
    /** Portasサブスク商材の購入導線を表示するかどうか */
    displayPortasSubscriptionPurchaseLead: boolean;
  } {
    return {
      member: null,
      memberStatus: null,
      ispMemberStatus: '',
      displayPortalPattern: DISPLAY_PORTAL_PATTERN.FIVE_A.NORMAL,
      DISPLAY_PORTAL_PATTERN: DISPLAY_PORTAL_PATTERN.FIVE_A,
      property: null,
      customer: null,
      isContractedConnectix: false,
      isProvidedConnectix: false,
      isDisplayConnectixFAQ: false,
      emInfoMessages: [],
      connectixLpUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_LP_URL,
      ScrollHint: require('../../../shared/libraries/scroll-hint/scroll-hint'),
      isMounting: true,
      isLinkedToMcloud: false,
      isDisplayNotLinkedMcloud: true,
      displayPortasSubscriptionPurchaseLead: true,
    };
  },
  emits: ['updateLeftMenuLogoFromPortalServiceFiveA'],
  async mounted(): Promise<void> {
    try {
      this.member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // Mcloud連携の判定
      if (this.member?.primaryKeyMcloud) {
        this.isLinkedToMcloud = true;
      } else if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        // Portas退会申し込み中 の場合Mcloud登録リンクは表示しない
        this.isDisplayNotLinkedMcloud = false;
      }

      const property: Property | null = this.$store.getters['propertyStore/property'];

      if (property?.apartmentId) {
        // 物件基本情報APIのレスポンスを使う処理
        this.processPropertyApiResponse();
        if (this.member.primaryKeyMye && property.uaType === UA_TYPE.FIVE_A) {
          // 契約基本情報APIのレスポンスを使う処理
          this.processCustomerApiResponse();
        }
      }
    } catch (error) {
      throw error;
    }

    this.$nextTick(() => {
      this.jsScrollable();
    });

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
      if (!this.member.primaryKeyMye) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.FIVE_A.NONE;
      }
    }

    // ISP会員ステータスが退会済みログイン不可となるとき
    if (this.memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.displayPortasSubscriptionPurchaseLead = false;
      if (!this.member.primaryKeyMye) {
        this.displayPortalPattern = DISPLAY_PORTAL_PATTERN.FIVE_A.NONE;
      }
    }
    this.isMounting = false;
  },
  methods: {
    /**
     * 物件基本情報を使う処理
     */
    processPropertyApiResponse(): void {
      const fiveAProperty: FiveAProperty | null = this.$store.getters['fiveACommonStore/property'];

      if (fiveAProperty instanceof FiveAProperty) {
        // Connectix提供物件かどうか
        if (fiveAProperty.op.sdwan.flag === '1') {
          this.isProvidedConnectix = true;
          this.isDisplayConnectixFAQ = true;
        }
        this.property = fiveAProperty;
        if (fiveAProperty.em_info_message !== null) {
          this.emInfoMessages = fiveAProperty.em_info_message.map((message) => {
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }
      }
    },
    /**
     * 契約基本情報を使う処理
     */
    processCustomerApiResponse(): void {
      // FiveA の契約基本情報をStoreから取得（e-mansion 契約基本情報取得API を使用）
      const fiveACustomer: FiveACustomer | null = this.$store.getters['fiveACommonStore/customer'];

      if (fiveACustomer instanceof FiveACustomer) {
        // Connectix契約済みかどうか
        if (fiveACustomer.op_connectix_contract_status === '1') {
          this.isContractedConnectix = true;

          if (this.isProvidedConnectix) {
            this.isDisplayConnectixFAQ = false;
          }
        }

        this.ispMemberStatus = getIspMemberStatusFiveA(this.memberStatus!, fiveACustomer);
        this.displayPortalPattern = getDisplayPortalPatternCaseFiveA(this.memberStatus!, this.ispMemberStatus);

        if (this.displayPortalPattern !== DISPLAY_PORTAL_PATTERN.FIVE_A.NORMAL) {
          this.isDisplayConnectixFAQ = false;
        }

        this.customer = fiveACustomer;
      }
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollable(): void {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    isDiaplySectionLeadTo5a(): boolean {
      const member: Member | null = this.$store.getters['memberStore/member'];
      const property: Property | null = this.$store.getters['propertyStore/property'];

      if (member?.primaryKeyMye && property?.uaType === UA_TYPE.FIVE_A) {
        return true;
      }
      return false;
    },
    /**
     * 「お住まいの建物専用サイトはこちら」のURL
     */
    getForBuildingUrl(): string {
      const property: Property = this.$store.getters['propertyStore/property'];
      if (property.oemType) {
        const latestDomainForOsumai = this.getLatestDomainForOsumai(property.oemType);

        if (property.apartmentId) {
          const digit8Id = convert6DigitIdTo8DigitId(property.apartmentId);
          return `${latestDomainForOsumai}/${digit8Id} `;
        }
      }
      return '';
    },
    getLatestDomainForOsumai(oemType: string): string {
      const oemMaster: Array<GetOemsMasterResponse> = this.$store.getters['oemsStore/oems'];
      const filtered = oemMaster.filter((item) => item.oemType === oemType);

      if (filtered.length > 1) {
        // oem_typeが同じレコードが複数存在する場合、 start_at が一番大きいレコード(最新のレコード) を採用する
        const latestOem = filtered.reduce((acc: GetOemsMasterResponse, current: GetOemsMasterResponse) => {
          if (acc.startAt < current.startAt) {
            return current;
          }
          return acc;
        });
        return latestOem.domainForOsumai;
      }

      return filtered[0].domainForOsumai;
    },
    getServiceType(): string {
      return UA_TYPE.FIVE_A;
    },
    isDisplayFAQComponent() {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED || this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED) {
        return true;
      }
      return false;
    },
    displayTsunaguOnlineShop(): boolean {
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && !this.member?.primaryKeyTnshop) {
        return false;
      }
      return true;
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    sendUpdateLeftMenuLogoAtPortalServiceFiveA(): void {
      this.$emit('updateLeftMenuLogoFromPortalServiceFiveA', true);
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * displayPortalPatternを更新する
     */
    async updateDisplayPortalPatternAtFiveA(): Promise<void> {
      this.member = this.$store.getters['memberStore/member'];

      await StoreExternalApiResponse.main();
      if (this.member?.primaryKeyMye) {
        this.processCustomerApiResponse();
      }
    },
  },
});
</script>
