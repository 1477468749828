import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import UcomIndex from '@/pages/ucom/index.vue';
import LinkService from '@/pages/ucom/link-service.vue';
import Linking from '@/pages/ucom/linking.vue';
import SubAccountLinking from '@/pages/ucom/sub-account-linking.vue';
import AccountManagementList from '@/pages/ucom/account-management/list.vue';
import AccountManagementInput from '@/pages/ucom/account-management/input.vue';
import AccountManagementConfirm from '@/pages/ucom/account-management/confirm.vue';
import AccountManagementCompleted from '@/pages/ucom/account-management/completed.vue';
import MailboxCapacityInput from '@/pages/ucom/mailbox-capacity/input.vue';
import MailboxCapacityConfirm from '@/pages/ucom/mailbox-capacity/confirm.vue';
import MailboxCapacityCompleted from '@/pages/ucom/mailbox-capacity/completed.vue';
import AccountOptionChangeAccountList from '@/pages/ucom/account-option-change/list.vue';
import AccountOptionChangeMailAddressInput from '@/pages/ucom/account-option-change/mail/input.vue';
import AccountOptionChangeMailAddressConfirm from '@/pages/ucom/account-option-change/mail/confirm.vue';
import AccountOptionChangeMailAddressCompleted from '@/pages/ucom/account-option-change/mail/completed.vue';
import AccountOptionChangeWebSpaceConfirm from '@/pages/ucom/account-option-change/web-space/confirm.vue';
import AccountOptionChangeWebSpaceCompleted from '@/pages/ucom/account-option-change/web-space/completed.vue';
import AccountInfoForDelete from '@/pages/ucom/account-management/account-info-for-delete.vue';
import AccountInfoForDeleteConfirmation from '@/pages/ucom/account-management/account-info-for-delete-confirmation.vue';
import AccountInfoForDeleteComplete from '@/pages/ucom/account-management/account-info-for-delete-complete.vue';
import MailPackInput from '@/pages/ucom/mail-pack/input.vue';
import MailPackConfirm from '@/pages/ucom/mail-pack/confirm.vue';
import MailPackComplete from '@/pages/ucom/mail-pack/complete.vue';
import VirusCheckInput from '@/pages/ucom/virus-check/input.vue';
import VirusCheckPurchaseConfirm from '@/pages/ucom/virus-check/purchase-confirm.vue';
import VirusCheckPurchaseComplete from '@/pages/ucom/virus-check/purchase-complete.vue';
import VirusCheckPauseConfirm from '@/pages/ucom/virus-check/pause-confirm.vue';
import VirusCheckPauseCompleted from '@/pages/ucom/virus-check/pause-completed.vue';
import WebSpaceInput from '@/pages/ucom/web-space/input.vue';
import WebSpaceConfirm from '@/pages/ucom/web-space/confirm.vue';
import WebSpaceComplete from '@/pages/ucom/web-space/complete.vue';
import UcomAlreadyLinked from '@/pages/ucom/entry/already-linked.vue';
import Input from '@/pages/ucom/entry/input.vue';
import Confirm from '@/pages/ucom/entry/confirm.vue';
import DuringRegistrationProcess from '@/pages/ucom/entry/during-registration-process.vue';
import UcomEntryRegistrationError from '@/pages/ucom/entry/registration-error.vue';
import Completed from '@/pages/ucom/entry/completed.vue';
import Contact from '@/pages/ucom/entry/contact.vue';
import RentalServiceInput from '@/pages/ucom/rental-service/input.vue';
import RentalServiceConfirm from '@/pages/ucom/rental-service/confirm.vue';
import RentalServiceComplete from '@/pages/ucom/rental-service/complete.vue';
import store from '@/store';
import { isTransitionNoLinked } from '.';
import { UA_TYPE } from '../shared/const/service-type';
import { PermissionControlService } from '../shared/services/permission/permission-control-service';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { SCREEN_ID } from '@/shared/const/screen-ids';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
/** UCOM ルーティング定義 */
const ucomRouter = [
  {
    path: '/ucom',
    name: 'UCOM光レジデンス サービストップ',
    component: UcomIndex,
    meta: {
      title: 'Portas | UCOM光 レジデンス 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）UCOM光 レジデンス会員専用トップページになります。',
      keywords: 'UCOM光 レジデンス 会員専用トップページ',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      const isLinkedToUcom: boolean = await store.dispatch('memberStore/isLinkedToUcom');
      if (isLinkedToUcom) {
        next();
        return;
      } else {
        return next({ path: '/login-forwarding' }); // トップページに遷移
      }
    },
  },
  //{
  //  path: '/ucom/link-service',
  //  name: 'UCOM光レジデンス 同時お申し込み',
  //  component: LinkService,
  //  meta: {
  //    title: 'Portas | UCOM光 レジデンス お客様番号連携、新規会員登録 | ポルタス',
  //    description: 'Portas（ポルタス）とUCOM光 レジデンスのお客様番号連携、新規会員登録を行います。',
  //    keywords: 'UCOM光 レジデンス同時お申し込みページ（新規会員登録、アカウント連携）'
  //  },
  //  beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  {
    path: '/ucom/linking',
    name: 'UCOM光レジデンス 既存サービスとの紐づけ',
    component: Linking,
    meta: {
      title: 'Portas | UCOM光 レジデンス お客様番号連携 | ポルタス',
      description: 'Portas（ポルタス）とUCOM光 レジデンスのお客様番号連携を行います。',
      keywords: 'UCOM光 レジデンス お客様番号連携',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (await isTransitionNoLinked(UA_TYPE.UCOM)) {
        return next();
      }
      return next({ path: '/platform' });
    },
  },
  {
    path: '/ucom/sub-account-linking',
    name: 'UCOM光レジデンス アカウント 既存サービスとの紐づけ',
    component: SubAccountLinking,
    meta: {
      title: 'Portas | UCOM光 レジデンス アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とUCOM光 レジデンスのアカウント連携を行います。',
      keywords: 'UCOM光 レジデンス アカウント連携',
    },
  },
  {
    path: '/ucom/entry/already-linked',
    name: 'UCOM光レジデンス 新規会員登録 お申し込み済',
    component: UcomAlreadyLinked,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み お申し込み済 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。お申し込み済',
      keywords: 'UCOM光 レジデンス お申し込み済'
    }
  },
  {
    path: '/ucom/entry/input',
    name: 'UCOM光レジデンス 新規会員登録 情報の入力',
    component: Input,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み お申し込み | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。お客様情報入力',
      keywords: 'UCOM光 レジデンス お申し込み入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      const member: Member | null = await store.dispatch('memberStore/member');

      // 既にお客様番号連携済みのとき
      if (member && member.primaryKeyUcom) {
        await AuthService.refresh();
        return next({ path: '/ucom/entry/already-linked' });
      }

      if (await isTransitionNoLinked(UA_TYPE.UCOM)) {
        return next();
      }
      return next({ path: '/platform' });
    },
  },
  {
    path: '/ucom/entry/confirm',
    name: 'UCOM光レジデンス 新規会員登録 入力内容の確認',
    component: Confirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。確認',
      keywords: 'UCOM光 レジデンス お申し込み確認',
    },
    // URL直叩き防止のため、直前のURLを判定する。
    // 情報の入力画面だった場合は入力内容の確認画面に遷移させる。
    // それ以外だった場合、情報の入力画面へ遷移させる。
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path === '/ucom/entry/input' || from.path === '/ucom/entry/during-registration-process') {
        next();
        return;
      }
      next({ path: '/ucom/entry/input' });
      return;
    },
  },
  {
    path: '/ucom/entry/during-registration-process',
    name: 'UCOM光レジデンス 新規会員登録 登録中',
    component: DuringRegistrationProcess,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み 登録中 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。登録中',
      keywords: 'UCOM光 レジデンス 登録中'
    },
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path === '/') {
        next({ path: '/ucom/entry/registration-error' });
      } else if (from.path !== '/ucom/entry/confirm') {
        next({ path: '/ucom/entry/input' });
      }
      next();
    }
  },
  {
    path: '/ucom/entry/registration-error',
    name: 'UCOM光レジデンス 新規会員登録 エラー',
    component: UcomEntryRegistrationError,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み エラー | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。エラー',
      keywords: 'UCOM光 レジデンス お申し込みエラー'
    }
  },
  {
    path: '/ucom/entry/completed',
    name: 'UCOM光レジデンス 新規会員登録 完了',
    component: Completed,
    meta: {
      title: 'Portas | UCOM光 レジデンス お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス のお申し込みはこちらから行ってください。完了',
      keywords: 'UCOM光 レジデンス お申し込み完了',
    },
    // URL直叩き防止のため、直前のURLを判定しする。
    // 新規会員登録 登録中だった場合は完了画面に遷移させる。
    // それ以外だった場合、総合トップへ遷移させる。
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path !== '/ucom/entry/during-registration-process') {
        next({ path: '/platform' });
        return;
      }
      next();
    },
  },
  {
    path: '/ucom/rental-service/input',
    name: 'UCOM光 機器レンタルサービス お申し込み 入力',
    component: RentalServiceInput,
    meta: {
      title: 'Portas | UCOM光 機器レンタルサービス お申し込み 入力 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 機器レンタルサービス のお申し込みはこちらから行ってください。入力',
      keywords: 'UCOM光 機器レンタルサービス お申し込み 入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      //UCOM光会員TOP, 機器レンタル申込確認画面からの遷移のみ許容
      if (!(from.path === '/ucom' || from.path === '/ucom/rental-service/confirm')) {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/rental-service/confirm',
    name: 'UCOM光 機器レンタルサービス お申し込み 確認',
    component: RentalServiceConfirm,
    meta: {
      title: 'Portas | UCOM光 機器レンタルサービス お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 機器レンタルサービス のお申し込みはこちらから行ってください。確認',
      keywords: 'UCOM光 機器レンタルサービス お申し込み 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 機器レンタルサービス お申し込み 入力画面からの遷移のみ許容
      if (from.path !== '/ucom/rental-service/input') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/rental-service/complete',
    name: 'UCOM光 機器レンタルサービス お申し込み 完了',
    component: RentalServiceComplete,
    meta: {
      title: 'Portas | UCOM光 機器レンタルサービス お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 機器レンタルサービス のお申し込みはこちらから行ってください。完了',
      keywords: 'UCOM光 機器レンタルサービス お申し込み 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 機器レンタルサービス お申し込み 確認画面からの遷移のみ許容
      if (from.path !== '/ucom/rental-service/confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/list',
    name: 'UCOM光レジデンス アカウントID管理 一覧',
    component: AccountManagementList,
    meta: {
      title: 'Portas | UCOM光 レジデンス アカウントID管理 一覧 | ポルタス',
      description: 'アカウントIDの登録／変更／削除が行えます。',
      keywords: 'UCOM光 レジデンス アカウントID管理 一覧',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/input',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　入力',
    component: AccountManagementInput,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　入力 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更はこちらから行ってください。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 戻るボタン押下時対応
      if (store.getters['ucomAccountManagementInputStore/accountName']) {
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/account-management/confirm') {
        if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
          return next({ path: '/ucom/account-option-change/account-list' });
        }
        return next({ path: store.getters['ucomAccountManagementInputStore/before'] });
      }
      //アカウントID情報一覧画面からの遷移
      if (from.path === '/ucom/account-option-change/account-list') {
        return next();
      }
      //アカウントID メールサービス解約確認からの遷移
      if (from.path === '/ucom/account-management/delete-confirmation') {
        return next();
      }
      // リロード対応
      if (from.path !== '/ucom/account-management/list') {
        if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
          return next({ path: '/ucom/account-option-change/account-list' });
        }
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/confirm',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　確認',
    component: AccountManagementConfirm,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更の内容をご確認いただけます。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      //ブラウザバッグ
      if (from.path === '/ucom/account-management/complete') {
        if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
          return next({ path: '/ucom/account-option-change/account-list' });
        }
        return next({ path: store.getters['ucomAccountManagementInputStore/before'] });
      }
      // UCOM光レジデンス アカウントID管理：登録・変更_入力画面からの遷移のみ対応
      if (from.path != '/ucom/account-management/input') {
        if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
          return next({ path: '/ucom/account-option-change/account-list' });
        }
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/completed',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　完了',
    component: AccountManagementCompleted,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　完了 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更手続きが完了しました。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御
      if (!(await PermissionControlService.isItemReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_UPDATE_COMPLETE, '02'))) {
        return next({ path: '/error' });
      }
      // UCOM光レジデンス アカウントID管理：登録・変更_確認画面からの遷移のみ対応
      if (from.path != '/ucom/account-management/confirm') {
        if (!(await PermissionControlService.isScreenReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_LIST))) {
          return next({ path: '/ucom/account-option-change/account-list' });
        }
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/account-list',
    name: 'UCOM アカウントIDオプション変更 アカウントID情報一覧',
    component: AccountOptionChangeAccountList,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 アカウントID情報一覧 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 アカウントID情報一覧',
    },
  },
  {
    path: '/ucom/mailbox-capacity/input',
    name: 'UCOM光レジデンス メールボックス容量変更：入力',
    component: MailboxCapacityInput,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：入力画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更はこちらから行ってください。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 入力画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 戻るボタン押下時対応
      if (store.getters['ucomMailboxCapacityStore/ucomAccountMailId']) {
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/mailbox-capacity/confirm') {
        return next({ path: '/ucom' });
      }
      // リロード対応
      if (from.path !== '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mailbox-capacity/confirm',
    name: 'UCOM光レジデンス メールボックス容量変更：確認',
    component: MailboxCapacityConfirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：確認画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更はこちらから行ってください。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 確認画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // メールボックス容量変更：入力画面からの遷移のみ対応
      if (from.path !== '/ucom/mailbox-capacity/input') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mailbox-capacity/completed',
    name: 'UCOM光レジデンス メールボックス容量変更：完了',
    component: MailboxCapacityCompleted,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：完了画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更手続きが完了しました。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 完了画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // メールボックス容量変更：確認画面からの遷移のみ対応
      if (from.path !== '/ucom/mailbox-capacity/confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/input',
    name: 'UCOM光レジデンス Webスペース容量変更：入力',
    component: WebSpaceInput,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：入力画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 入力画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      // 戻るボタン押下時対応
      if (store.getters['ucomWebSpaceStore/ucomAccountMailId']) {
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/web-space/confirm') {
        return next({ path: '/ucom' });
      }
      // リロード対応
      if (from.path !== '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/confirm',
    name: 'UCOM光レジデンス Webスペース容量変更：確認',
    component: WebSpaceConfirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：確認画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 確認画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      // Webスペース容量変更：入力画面からの遷移のみ対応
      if (from.path !== '/ucom/web-space/input') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/complete',
    name: 'UCOM光レジデンス Webスペース容量変更：完了',
    component: WebSpaceComplete,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：完了画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 完了画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      // Webスペース容量変更：確認画面からの遷移のみ対応
      if (from.path !== '/ucom/web-space/confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mail-pack/input',
    name: 'UCOM メールサービス購入：入力',
    component: MailPackInput,
    meta: {
      title: 'Portas | UCOM メールサービス購入：入力 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 戻るボタン押下時対応
      if (store.getters['ucomMailPackStore/productName']) {
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/mail-pack/confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mail-pack/confirm',
    name: 'UCOM メールサービス購入：確認',
    component: MailPackConfirm,
    meta: {
      title: 'Portas | UCOM メールサービス購入：確認 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // UCOM メールサービス購入：入力画面からの遷移のみ対応
      if (from.path !== '/ucom/mail-pack/input') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mail-pack/complete',
    name: 'UCOM メールサービス購入：完了',
    component: MailPackComplete,
    meta: {
      title: 'Portas | UCOM メールサービス購入：完了 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // UCOM メールサービス購入：確認画面からの遷移のみ対応
      if (from.path !== '/ucom/mail-pack/confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/mail-input',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 入力',
    component: AccountOptionChangeMailAddressInput,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 入力 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (
        from.path === '/ucom/account-option-change/account-list' ||
        from.path === '/ucom/account-management/list' ||
        from.path === '/ucom/account-management/completed' ||
        from.path === '/ucom/account-management/delete-confirmation' ||
        from.path === '/ucom/account-option-change/mail-confirm'
      ) {
        store.commit('ucomAccountOptionStore/beforePath', from.path);
        return next();
      }
      // 戻るボタン押下時対応
      if (store.getters['ucomAccountOptionStore/accountName']) {
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/account-option-change/mail-confirm') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      // リロード対応
      if (from.path !== '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
    },
  },
  {
    path: '/ucom/account-option-change/mail-confirm',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 確認',
    component: AccountOptionChangeMailAddressConfirm,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/account-option-change/mail-input') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/mail-completed',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 完了',
    component: AccountOptionChangeMailAddressCompleted,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/account-option-change/mail-confirm') {
        next({ path: '/ucom/account-option-change/account-list' });
        return;
      }
      return next();
    },
  },
  {
    path: '/ucom/virus-check/input',
    name: 'UCOM ウイルスチェック：入力',
    component: VirusCheckInput,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：入力 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 戻るボタン押下時対応
      if (store.getters['ucomVirusCheckStore/pauseState'] || store.getters['ucomVirusCheckStore/purchaseState']) {
        store.commit('ucomVirusCheckStore/setPurchaseState', undefined);
        store.commit('ucomVirusCheckStore/setPauseState', undefined);
        return next();
      }
      // ブラウザバック対応
      if (from.path === '/ucom/virus-check/purchase-confirm' || from.path === '/ucom/virus-check/pause-confirm') {
        return next({ path: '/ucom' });
      }
      // リロード対応
      if (from.path !== '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/virus-check/purchase-confirm',
    name: 'UCOM ウイルスチェック：購入／解約確認',
    component: VirusCheckPurchaseConfirm,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：購入／解約確認 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：購入／解約確認',
    },
    beforeEnter: async (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/virus-check/input') {
        return next({ path: '/ucom' });
      }
      // 遷移先で必要となるデータがストアされているかチェック
      const purchaseState = store.getters['ucomVirusCheckStore/purchaseState'];
      if (!purchaseState) {
        // ストアされていなければ UCOM アカウントID情報一覧 画面に戻る
        next({ path: '/ucom/account-option-change/account-list' });
        return;
      }
      next();
    },
  },
  {
    path: '/ucom/virus-check/purchase-complete',
    name: 'UCOM ウイルスチェック：購入／解約完了',
    component: VirusCheckPurchaseComplete,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：購入／解約完了 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：購入／解約完了',
    },
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // お申し込み内容がストアされていなければ UCOM 会員専用トップ 画面に戻る
      const purchaseState = store.getters['ucomVirusCheckStore/purchaseState'];
      if (!purchaseState) {
        next({ path: '/ucom' });
        return;
      }
      // 通知先メールアドレスがストアされていなければ UCOM 会員専用トップ 画面に戻る
      const notifyMailAddress = store.getters['ucomVirusCheckStore/notifyMailAddress'];
      if (!notifyMailAddress) {
        next({ path: '/ucom' });
        return;
      }
      next();
    },
  },
  {
    path: '/ucom/virus-check/pause-confirm',
    name: 'UCOM ウイルスチェック：一時停止／開始確認',
    component: VirusCheckPauseConfirm,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：一時停止／開始確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス ウイルスチェック 一時停止／開始確認ページです。',
      keywords: 'UCOM, ウイルスチェック, 一時停止, 開始, 確認, Portas, ポルタス',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // UCOM ウイルスチェック：入力から遷移のみ対応
      if (from.path === '/ucom/virus-check/input') {
        return next();
      }
      // UCOM_会員専用トップ画面へ戻す
      return next({ path: '/ucom' });
    },
  },
  {
    path: '/ucom/account-option-change/web-space/confirm',
    name: 'UCOM アカウントIDオプション変更 Webスペース登録 確認',
    component: AccountOptionChangeWebSpaceConfirm,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 Webスペース登録 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 Webスペース登録 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE))) {
        return next({ path: '/error' });
      }
      // UCOM アカウントIDオプション変更 アカウントID情報一覧画面からの遷移のみ対応
      if (from.path != '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/web-space/completed',
    name: 'UCOM アカウントIDオプション変更 Webスペース登録 完了',
    component: AccountOptionChangeWebSpaceCompleted,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 Webスペース登録 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 Webスペース登録 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE))) {
        return next({ path: '/error' });
      }
      // UCOM アカウントIDオプション変更 Webスペース登録 確認からの遷移のみ対応
      if (from.path != '/ucom/account-option-change/web-space/confirm') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },

  {
    path: '/ucom/account-management/delete',
    name: 'UCOM アカウントID管理 削除 確認',
    component: AccountInfoForDelete,
    meta: {
      title: 'Portas | UCOM アカウントID管理 削除 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 削除 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_INFO_FOR_CANCEL))) {
        return next({ path: '/error' });
      }
      // 戻るボタン押下時対応
      if (store.getters['ucomAccountManagementStore/ucomAccountId']) {
        return next();
      }
      if (from.path !== '/ucom/account-management/list') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/delete-confirmation',
    name: 'UCOM アカウントID管理 メールサービス解約確認',
    component: AccountInfoForDeleteConfirmation,
    meta: {
      title: 'Portas | UCOM アカウントID管理 メールサービス解約確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 メールサービス解約確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_CANCEL_CONFIRMATION))) {
        return next({ path: '/error' });
      }
      if (from.path !== '/ucom/account-management/delete') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/delete-complete',
    name: 'UCOM アカウントID管理 削除 完了',
    component: AccountInfoForDeleteComplete,
    meta: {
      title: 'Portas | UCOM アカウントID管理 削除 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 削除 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_CANCEL_COMPETE))) {
        return next({ path: '/error' });
      }
      if (from.path !== '/ucom/account-management/delete' && from.path !== '/ucom/account-management/delete-confirmation') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/virus-check/pause-completed',
    name: 'UCOM ウイルスチェック：一時停止/開始完了',
    component: VirusCheckPauseCompleted,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：一時停止/開始完了 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：一時停止/開始完了',
    },
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      if (from.path !== '/ucom/virus-check/pause-confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
];
export default ucomRouter;
