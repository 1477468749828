import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8debed8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "my-section-outer" }
const _hoisted_3 = { class: "my-section-outer" }
const _hoisted_4 = { class: "my-section-outer connectix" }
const _hoisted_5 = { class: "my-section-outer coupon-and-campaign" }
const _hoisted_6 = { class: "my-section-wrapper tsunagu-online-shop" }
const _hoisted_7 = { class: "my-section" }
const _hoisted_8 = {
  id: "faq-at-top",
  class: "my-section-wrapper"
}
const _hoisted_9 = { class: "my-section padding-top-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_LoginAndRegisterGuideAtTop = _resolveComponent("LoginAndRegisterGuideAtTop")!
  const _component_MainSwiperComponent = _resolveComponent("MainSwiperComponent")!
  const _component_ConnectixAtTop = _resolveComponent("ConnectixAtTop")!
  const _component_CouponAndCampaignAtTop = _resolveComponent("CouponAndCampaignAtTop")!
  const _component_TsunaguOnlineShop = _resolveComponent("TsunaguOnlineShop")!
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMounted)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LoginAndRegisterGuideAtTop)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MainSwiperComponent, {
        status: _ctx.status,
        passEventToParentComponent: _ctx.handlePassedEventFromChildComponent
      }, null, 8, ["status", "passEventToParentComponent"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ConnectixAtTop)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CouponAndCampaignAtTop, { passEventToParentComponent: _ctx.handlePassedEventFromChildComponent }, null, 8, ["passEventToParentComponent"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_TsunaguOnlineShop, { ref: "tsunaguOnlineShop" }, null, 512)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_FrequentlyAskedQuestions, { serviceType: 'top' })
      ])
    ])
  ]))
}