<template>
  <div class="main-swiper-component">
    <div class="mainvisual">
      <!-- 総合トップ画面用 -->
      <template v-if="status === 'top'">
        <div class="top-mainvisual">
          <div class="my-top-swiper-wrapper">
            <section class="my-top-swiper">
              <div class="my-title">
                <h2>Portasサービス</h2>
              </div>
              <div class="my-lead-sentence">
                <p>Portasサービスとは、Portasに会員登録いただくとご利用いただける月額定額制サービスです。</p>
                <p>初回お申し込み時は無料期間が適用されお試しいただけます。</p>
                <a :href="portasServiceFreePeriodChangeNoticeUrl" target="_blank" class="red link"><b>【重要】Portasサービスの無料期間変更のお知らせ</b></a>
                <p>複数購入でセット料金が適用されます。</p>
              </div>
              <div class="my-top-swiper-outer">
                <div v-bind:class="{ 'my-visibility-hidden': isLoadingCarouselImages }">
                  <swiper
                    :loop="true"
                    :speed="1500"
                    :centered-slides="true"
                    :loop-additional-slides="1"
                    :autoplay="{ delay: 8000, disableOnInteraction: false }"
                    :navigation="{ nextEl: '.main-swiper-button-next', prevEl: '.main-swiper-button-prev' }"
                    :pagination="{ enabled: true, clickable: true }"
                    :modules="SwiperModules"
                    :space-between="40"
                  >
                    <!--あんしんサポートセット画像-->
                    <swiper-slide>
                      <a :href="portasServiceImageSetplusNotLoggedIn.url" target="_blank" class="for-image-link">
                        <img :src="portasServiceImageSetplusNotLoggedIn.pc" alt="" class="pc img-center img-pc" v-on:load="updateIsImagesLoaded(true)" />
                        <img :src="portasServiceImageSetplusNotLoggedIn.sp" alt="" class="sp img-center img-sp" v-on:load="updateIsImagesLoaded(true)" />
                      </a>
                    </swiper-slide>
                    <!--U-NEXT-->
                    <swiper-slide>
                      <a :href="platformUNextImageBeforeLogin.url" target="_blank" class="for-image-link">
                        <img :src="platformUNextImageBeforeLogin.pc" alt="" class="pc img-center img-pc" v-on:load="updateIsImagesLoaded(true)" />
                        <img :src="platformUNextImageBeforeLogin.sp" alt="" class="sp img-center img-sp" v-on:load="updateIsImagesLoaded(true)" />
                      </a>
                    </swiper-slide>
                    <!--Portasサービスの商品画像(外部リンク)-->
                    <swiper-slide v-for="image in portasServiceImageAtTopCarouselNotLoggedIn" :key="image.url">
                      <a :href="image.url" target="_blank" :class="['for-image-link', image.name]">
                        <img :src="image.pc" class="img-pc" />
                        <img :src="image.sp" class="img-sp" />
                      </a>
                    </swiper-slide>
                  </swiper>
                  <div class="main-swiper-button-prev swiper-button-prev"></div>
                  <div class="main-swiper-button-next swiper-button-next"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </template>
      <!-- 会員専用トップ画面用 -->
      <template v-else-if="status === 'members-only-top'">
        <div class="container">
          <!-- カルーセル表示しないとき(画像1枚のとき) -->
          <div v-if="!displayWithCarousel()">
            <img :src="platformMainImage.pc" alt="" class="pc img-center img-pc" v-on:load="updateIsImagesLoaded(true)" />
            <img :src="platformMainImage.sp" alt="" class="sp img-center img-sp" v-on:load="updateIsImagesLoaded(true)" />
          </div>
          <!-- カルーセル表示するとき(画像2枚以上のとき) -->
          <div v-else-if="displayWithCarousel()" v-bind:class="{ 'my-visibility-hidden': isLoadingCarouselImages }">
            <swiper
              :loop="true"
              :speed="1500"
              :centered-slides="true"
              :loop-additional-slides="1"
              :autoplay="{ delay: 8000, disableOnInteraction: false }"
              :navigation="{ nextEl: '.main-swiper-button-next', prevEl: '.main-swiper-button-prev' }"
              :pagination="{ enabled: true, clickable: true }"
              :modules="SwiperModules"
              :space-between="40"
            >
              <!-- Portas_2024年3月キャンペーン -->
              <swiper-slide v-for="image in portasServiceImageAtTopCampaignLoggedIn" :key="image.url">
                <a :href="image.url" target="_blank" class="for-image-link mineo">
                  <img :src="image.pc" class="img-pc" />
                  <img :src="image.sp" class="img-sp" />
                </a>
              </swiper-slide>
              <!--あんしんサポートセット画像-->
              <swiper-slide>
                <a :href="portasServiceImageSetplusLoggedIn.url" target="_blank" class="for-image-link">
                  <img :src="portasServiceImageSetplusLoggedIn.pc" alt="" class="pc img-center img-pc" v-on:load="updateIsImagesLoaded(true)" />
                  <img :src="portasServiceImageSetplusLoggedIn.sp" alt="" class="sp img-center img-sp" v-on:load="updateIsImagesLoaded(true)" />
                </a>
              </swiper-slide>
              <!-- U-NEXT画像を表示する -->
              <swiper-slide>
                <a :href="platformUNextImage.url" target="_blank" class="for-image-link">
                  <img :src="platformUNextImage.pc" alt="" class="pc img-center img-pc" v-on:load="updateIsImagesLoaded(true)" />
                  <img :src="platformUNextImage.sp" alt="" class="sp img-center img-sp" v-on:load="updateIsImagesLoaded(true)" />
                </a>
              </swiper-slide>
              <!--Portasサービスの商品画像(外部リンク)-->
              <swiper-slide v-for="image in portasServiceImageAtTopCarouselLoggedIn" :key="image.url">
                <a :href="image.url || image.url" target="_blank" :class="['for-image-link', image.name]">
                  <img :src="image.pc" class="img-pc" />
                  <img :src="image.sp" class="img-sp" />
                </a>
              </swiper-slide>
              <!-- Connectix画像を表示する -->
              <template v-if="isDisplayConnectix">
                <swiper-slide>
                  <img :src="platformConnectixImage.pc" alt="" class="pc" v-on:load="updateIsImagesLoaded(true)" />
                  <img :src="platformConnectixImage.sp" alt="" class="sp" v-on:load="updateIsImagesLoaded(true)" />
                  <!-- ISPに紐づいていないとき(会員ステータス: 5以外のとき)、「詳しく見るを表示しない」  -->
                  <div v-if="property && memberStatus?.status === memberStatusType.PROPERTY_LINKED">
                    <a v-if="property.uaType === '1'" :href="eMansionConnectixLpUrl" class="btn01" target="My e-mansion"><span>詳しく見る</span></a>
                    <a v-else-if="property.uaType === '2'" :href="fiveAConnectixLpUrl" class="btn01" target="Five.A"><span>詳しく見る</span></a>
                    <a v-else-if="property.uaType === '3'" :href="ucomConnectixLpUrl" class="btn01" target="UCOM"><span>詳しく見る</span></a>
                  </div>
                </swiper-slide>
              </template>

              <!-- つなぐオンラインショップの商品画像(スクレイピング処理する) -->
              <swiper-slide v-for="image in tsunaguOnlineShopProductImageListAtPlatform" :key="image.url">
                <a :href="image.url" class="for-image-link display-fetch-html-at-platform-swiper">
                  <img :src="image.pc" alt="" class="img-pc" v-on:load="updateIsImagesLoaded(true)" />
                  <img :src="image.sp" alt="" class="img-sp" v-on:load="updateIsImagesLoaded(true)" />
                </a>
              </swiper-slide>
            </swiper>
            <div class="main-swiper-button-prev swiper-button-prev"></div>
            <div class="main-swiper-button-next swiper-button-next"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
div.my-top-swiper-wrapper {
  max-width: 960px;
  margin: 0 auto;
}

div.my-top-swiper-outer {
  max-width: 640px;
  margin: 0 auto;
}

section.my-top-swiper {
  & div.my-title {
    margin-bottom: 16px;
    padding-left: 16px;

    & h2 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
      line-height: 1.6;
      border-bottom: 3px solid #cf1225;
    }
  }

  & div.my-lead-sentence {
    margin-bottom: 16px;
    padding: 0 16px;

    & p {
      line-height: 1.6;
    }
  }
}

.float-on-image-txt {
  position: absolute;
  color: #fff;
  left: 7.5%;
  max-width: 60%;
  bottom: 14%;
  line-height: 1.6;
  text-align: left;
}

.btn {
  position: absolute;
  margin: 0;
  top: 80%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.mainvisual {
  overflow-x: hidden;
  position: relative;
}

.top-mainvisual {
  overflow-x: hidden;
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}

.mainvisual .swiper-slide {
  position: relative;
}

.mainvisual {
  & .swiper-slide {
    & a {
      display: block;
      position: absolute;
      left: 60px;
      bottom: 50px;
      width: 320px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;

      &.for-image-link {
        position: static;
        width: auto;
      }
    }

    & img {
      width: 100%;
    }
  }
}

.mainvisual .swiper-slide a.btn01 {
  border: 1px solid #fff;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

.mainvisual .swiper-slide a.btn02 {
  border: 1px solid #333;
  color: #333;
  background-color: rgba(255, 255, 255, 0.4);
}

.mainvisual .swiper-slide a span {
  display: block;
  position: relative;
  width: 100%;
}

.mainvisual .swiper-slide a span::before,
.mainvisual .swiper-slide a span::after {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: '';
  vertical-align: middle;
}

.mainvisual .swiper-slide a span::before {
  right: -40px;
  width: 80px;
  height: 1px;
  background: #fff;
}

.mainvisual .swiper-slide a span::after {
  right: -38px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  transform: rotate(45deg);
}

.mainvisual .swiper-slide a.btn01 span::before {
  background: #fff;
}

.mainvisual .swiper-slide a.btn01 span::after {
  border-top: 1px solid #fff;
}

.mainvisual .swiper-slide a.btn02 span::before {
  background: #333;
}

.mainvisual .swiper-slide a.btn02 span::after {
  border-top: 1px solid #333;
}

.mainvisual .swiper-slide img:nth-child(2) {
  display: none;
}

/** swiperスタイル上書き用 */
div {
  &.swiper {
    overflow: visible;
  }
}

.is-connectix-contract-image {
  img {
    width: 100%;

    &.sp {
      display: none;
    }

    &.pc {
      display: block;
    }
  }
}

.img-sp {
  display: none;
}

.my-visibility-hidden {
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .mainvisual .swiper-slide a {
    left: 20%;
    bottom: 70px;
    width: 60%;
  }

  .mainvisual .swiper-slide a span::before {
    right: -25px;
    width: 50px;
  }

  .mainvisual .swiper-slide a span::after {
    right: -24px;
  }

  .mainvisual .swiper-slide img:nth-child(1) {
    display: none;
  }

  .mainvisual .swiper-slide img:nth-child(2) {
    display: block;
  }

  .is-connectix-contract-image {
    img {
      &.sp {
        display: block;
      }

      &.pc {
        display: none;
      }
    }
  }

  .img-pc {
    display: none;
  }

  .img-sp {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  section.my-top-swiper {
    & div.my-title {
      & h2 {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (min-width: 1070px) {
  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1069px) {
  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 17px;
  }
}

@media only screen and (min-width: 881px) and (max-width: 1023px) {
  .float-on-image-txt {
    bottom: 14%;
    max-width: 50%;
    font-size: 14px;
  }
}

@media screen and (min-width: 831px) and (max-width: 880px) {
  .float-on-image-txt {
    position: absolute;
    color: #fff;
    left: 7.5%;
    max-width: 60%;
    bottom: 14%;
    font-size: 14px;
    line-height: 1.6;
  }
}

@media screen and (min-width: 786px) and (max-width: 830px) {
  .float-on-image-txt {
    position: absolute;
    color: #fff;
    left: 7.5%;
    max-width: 60%;
    bottom: 14%;
    font-size: 14px;
    line-height: 1.6;
  }
}

@media screen and (min-width: 768px) and (max-width: 785px) {
  .float-on-image-txt {
    position: absolute;
    color: #fff;
    left: 7.5%;
    max-width: 60%;
    bottom: 14%;
    font-size: 12px;
    line-height: 1.6;
  }
}

@media screen and (max-width: 450px) {
  .float-on-image-txt {
    bottom: 20px;
    font-size: 18px;
    bottom: 50px;
  }
}

@media screen and (max-width: 420px) {
  .float-on-image-txt {
    bottom: 20px;
    font-size: 16px;
    bottom: 20px;
  }
}

@media screen and (max-width: 350px) {
  .float-on-image-txt {
    bottom: 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 300px) {
  .float-on-image-txt {
    bottom: 20px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1250px) {
  section.my-top-swiper {
    & div.my-title {
      padding-left: 0px;
    }

    & div.my-lead-sentence {
      padding: 0;
    }
  }
}
</style>
<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import {
  PLATFORM_CONNECTIX_IMAGE,
  PLATFORM_MAIN_IMAGE,
  PLATFORM_SWIPER_IMAGE_LIST,
  PLATFORM_UNEXT_IMAGE,
  PLATFORM_UNEXT_IMAGE_BEFORE_LOGIN,
  PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_LOGGED_IN,
  PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_NOT_LOGGED_IN,
  PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_LOGGED_IN,
  PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_NOT_LOGGED_IN,
  PORTAS_SERVICE_IMAGE_SETPLUS_LOGGED_IN,
  PORTAS_SERVICE_IMAGE_SETPLUS_NOT_LOGGED_IN,
  TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_NOT_LOGGED_IN,
  TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_PLATFORM,
  TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_WIFI_CONNECTIX,
  TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_CAMPAIGN,
  TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_GOODS,
  TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_INFORMATION,
} from '@/shared/const/platform-image';

import '@/styles/swiper/my-navigation.scss';
import '@/styles/swiper/my-pagination.scss';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'mainvisual',
  props: {
    status: {
      type: String,
    },
    isDisplayConnectix: {
      type: Boolean,
    },
    passEventToParentComponent: {
      type: Function,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup: () => ({ SwiperModules: [Autoplay, Navigation, Pagination] }),
  data: () => ({
    eMansionConnectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
    fiveAConnectixLpUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_LP_URL,
    ucomConnectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
    portasServiceFreePeriodChangeNoticeUrl: process.env.VUE_APP_PORTAS_SERVICE_FREE_PERIOD_CHANGE_NOTICE_URL,
    // swiper用画像 (メイン画像とConnectix画像)
    swiperContentListAtPlatform: PLATFORM_SWIPER_IMAGE_LIST,
    // メイン画像1枚
    platformMainImage: PLATFORM_MAIN_IMAGE,
    // Portas会員専用トップ用のswiper用画像 U-NEXT
    platformUNextImage: PLATFORM_UNEXT_IMAGE,
    // Portas総合トップ用のswiper用画像 U-NEXT
    platformUNextImageBeforeLogin: PLATFORM_UNEXT_IMAGE_BEFORE_LOGIN,
    // swiper用Connectix画像
    platformConnectixImage: PLATFORM_CONNECTIX_IMAGE,
    // swiper用画像 (クリックするとページ内スクロールする用の画像)
    tsunaguOnlineShopScrollToImageGoods: TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_GOODS,
    // swiper用画像 (クリックするとページ内スクロールする用の画像)
    tsunaguOnlineShopScrollToImageCampaign: TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_CAMPAIGN,
    // swiper用画像 (クリックするとページ内スクロールする用の画像)
    tsunaguOnlineShopScrollToImageInformation: TSUNAGU_ONLINE_SHOP_SCROLL_TO_IMAGE_INFORMATION,
    // swiper用画像（Portasサービス）
    portasServiceImageAtTopCarouselLoggedIn: PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_LOGGED_IN,
    portasServiceImageAtTopCarouselNotLoggedIn: PORTAS_SERVICE_IMAGE_AT_TOP_CAROUSEL_NOT_LOGGED_IN,
    // swiper用画像（Portasサービスあんしんサポートセットプラス)
    portasServiceImageSetplusLoggedIn: PORTAS_SERVICE_IMAGE_SETPLUS_LOGGED_IN,
    portasServiceImageSetplusNotLoggedIn: PORTAS_SERVICE_IMAGE_SETPLUS_NOT_LOGGED_IN,
    // 総合トップ用のswiper用画像 (クリックするとスクレイピング処理する用の画像)
    tsunaguOnlineShopProductImageListAtNotLoggedIn: TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_NOT_LOGGED_IN,
    // Portas会員専用トップ用のswiper用画像 (クリックするとスクレイピング処理する用の画像)
    tsunaguOnlineShopProductImageListAtPlatform: TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_PLATFORM,
    // swiper用 Wifi Connectix画像 (クリックするとスクレイピング処理する用の画像)
    tsunaguOnlineShopProductImageListWifiConnectix: TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_WIFI_CONNECTIX,
    //swiper用画像_キャンペーン用（クリックすると外部サイトへ移動する用の画像）
    portasServiceImageAtTopCampaignNotLoggedIn: PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_NOT_LOGGED_IN,
    //swiper用画像_キャンペーン用（クリックすると外部サイトへ移動する用の画像）
    portasServiceImageAtTopCampaignLoggedIn: PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_LOGGED_IN,
    // ファーストビューで表示される画像のうち、読み込み終わった件数
    loadedList: new Array<boolean>(),

    // 会員情報
    member: null as Member | null,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 会員ステータスタイプ
    memberStatusType: MEMBER_STATUS,
    // 物件情報
    property: null as Property | null,
    // カルーセルの画像読み込み中かどうか
    isLoadingCarouselImages: true,
  }),
  emits: ['isImagesLoadedMainSwiper'],
  mounted(): void {
    this.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];

    if (this.member?.propertyId) {
      this.property = this.$store.getters['propertyStore/property'];
    }

    this.isLoadingCarouselImages = false;

    if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      if (!this.member?.primaryKeyTnshop) {
        this.tsunaguOnlineShopProductImageListAtPlatform = [];
        this.tsunaguOnlineShopScrollToImageGoods = [];
        this.tsunaguOnlineShopScrollToImageCampaign = [];
        this.tsunaguOnlineShopScrollToImageInformation = [];
      }
    }
    this.$nextTick(() => {
      [...document.getElementsByClassName('display-fetch-html-at-platform-swiper')].map((element) => {
        return element.addEventListener('click', this.passEventWrapper);
      });
    });
  },
  methods: {
    updateIsImagesLoaded(value: boolean) {
      this.loadedList.push(value);
      this.changeIsImagesLoaded();
    },
    changeIsImagesLoaded(): void {
      if (this.isDisplayConnectix && this.loadedList.length === this.swiperContentListAtPlatform.length * 2) {
        this.$emit('isImagesLoadedMainSwiper', true); // index.vue 宛てに画像の読み込み終了を通知
      } else if (!this.isDisplayConnectix) {
        this.$emit('isImagesLoadedMainSwiper', true); // index.vue 宛てに画像の読み込み終了を通知
      }
    },
    /**
     * Portas会員専用トップにて、画像をカルーセル表示するかどうか
     */
    displayWithCarousel(): boolean {
      if (this.isDisplayConnectix) {
        return true;
      }

      const numberOfImages = this.swiperContentListAtPlatform.length + this.tsunaguOnlineShopProductImageListAtPlatform.length;
      if (numberOfImages > 1) {
        return true;
      }

      return false;
    },
    /**
     * swiperの詳しく見るボタンをクリックされたときのイベントを渡す
     */
    passEventWrapper(e: Event): void {
      this.passEventToParentComponent(e);
    },
  },
  watch: {
    /**
     * 親から渡される isDisplayConnectix の値について、計算された後の値を採用するようにしたいがための処理
     * 本来は親子間のライフサイクルフックを考慮した実装にするべきだが、今更怖いのでwatchで対応することにした
     */
    isDisplayConnectix: {
      handler: function () {
        if (this.isDisplayConnectix) {
          this.swiperContentListAtPlatform = PLATFORM_SWIPER_IMAGE_LIST;
          // swiperにて、Connectix用の画像を表示しないようにする処理
        } else {
          this.swiperContentListAtPlatform = PLATFORM_SWIPER_IMAGE_LIST.filter((item) => item.isConnectix === false);
        }
      },
    },
  },
});
</script>
