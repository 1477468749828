import { createStore } from 'vuex';

import { eMansionErrorDisplayInfoStore } from '@/store/e-mansion/e-mansion-error-display-info';
import { eMansionConnectixEntryStore } from './connectix/e-mansion-connectix-entry-store';
import { fiveAConnectixEntryStore } from './connectix/five-a-connectix-entry-store';
import { ucomConnectixEntryStore } from './connectix/ucom-connectix-entry-store';
import { contractsSubjectToCancelStore } from './contracts-subject-to-cancel-store';
import { eMansionAccountStore } from './e-mansion/e-mansion-account-store';
import { eMansionCommonStore } from './e-mansion/e-mansion-common-store';
import { eMansionEntryStore } from './e-mansion/e-mansion-entry-store';
import { eMansionHomepageCapacityStore } from './e-mansion/e-mansion-homepage-capacity-store';
import { eMansionHomepageOpenStore } from './e-mansion/e-mansion-homepage-open-store';
import { eMansionLinkingStore } from './e-mansion/e-mansion-linking-store';
import { eMansionMailPasswordStore } from './e-mansion/e-mansion-mail-password-store';
import { eMansionMailStore } from './e-mansion/e-mansion-mail-store';
import { eMansionMainMailAddressStore } from './e-mansion/e-mansion-main-mail-address-store';
import { eMansionSubAccountStore } from './e-mansion/e-mansion-sub-account-store';
import { errorMessageStore } from './error-message-store';
import { errorStore } from './error-store';
import { fiveACommonStore } from './five-a/five-a-common-store';
import { fiveAEntryStore } from './five-a/five-a-entry-store';
import { gamewithMemberEditStore } from './gamewith/gamewith-member-edit-store';
import { hasConnectixSubjectToCancelStore } from './has-connectix-subject-to-cancel-store';
import { invoiceHistoryListDtoStore } from './invoice-history-list-store';
import { maintenanceStore } from './maintenance';
import { maintenanceScreenStore } from './maintenance-screen';
import { mcloudCommonStore } from './mcloud/mcloud-common-store';
import { mcloudLinkingStore } from './mcloud/mcloud-linking-store';
import { memberStore } from './member';
import { oemsStore } from './oems';
import { paymentMethodStore } from './payment-method-store';
import { permissionStore } from './permission-store';
import { contactsStore } from './platform/contacs-store';
import { platformCancelStore } from './platform/platform-cancel-store';
import { platformEditStore } from './platform/platform-edit-store';
import { platformEntryStore } from './platform/platform-entry-store';
import { platformProductEntryStore } from './platform/platform-product-entry-store';
import { productCancelResultStore } from './product-cancel-result-store';
import { productsStore } from './products-store';
import { propertyStore } from './property';
import { servicePlanTypeStore } from './service-plan-type-store';
import { taxStore } from './tax-store';
import { tsunaguOnlineProductHtmlDisplayOnPcStore } from './tnos/tsunagu-online-product-html-display-on-pc-store';
import { tsunaguOnlineProductHtmlDisplayOnSpStore } from './tnos/tsunagu-online-product-html-display-on-sp-store';
import { ucomAccountManagementDeleteStore } from './ucom/ucom-account-management-delete-store';
import { ucomAccountManagementInputStore } from './ucom/ucom-account-management-input-store';
import { ucomAccountManagementStore } from './ucom/ucom-account-management-store';
import { ucomAccountOptionStore } from './ucom/ucom-account-option-store';
import { ucomAccountStore } from './ucom/ucom-account-store';
import { ucomCommonStore } from './ucom/ucom-common-store';
import { ucomEntryStore } from './ucom/ucom-entry-store';
import { ucomLinkingStore } from './ucom/ucom-linking-store';
import { ucomMailPackStore } from './ucom/ucom-mail-pack-store';
import { ucomMailboxCapacityStore } from './ucom/ucom-mailbox-capacity-store';
import { ucomRentalServiceStore } from './ucom/ucom-rental-service-store';
import { ucomVirusCheckStore } from './ucom/ucom-virus-check-store';
import { ucomWebSpaceRegisterStore } from './ucom/ucom-web-space-register-store';
import { ucomWebSpaceStore } from './ucom/ucom-web-space-store';
import { userScreenTransitionStore } from './user/user-screen-transition-store';

/** 親 State */
const rootState = {};
/** 親 State の型 : 子 Store の型定義に使用する */
export type RootState = typeof rootState;

/** 親 Store */
export default createStore({
  state: rootState,
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    ucomCommonStore, // UCOM 共通系
    ucomEntryStore, // UCOM 新規会員登録
    ucomConnectixEntryStore, // UCOM Connectix お申し込み
    ucomLinkingStore, // UCOM アカウント連携
    eMansionCommonStore, // e-mansion 共通
    eMansionConnectixEntryStore, // e-mansion Connectix お申し込み
    eMansionLinkingStore, // e-mansion アカウント連携
    platformEntryStore, // Platform 新規会員登録
    platformEditStore, // Platform 会員情報変更
    memberStore, // 会員情報
    propertyStore, // 物件情報
    paymentMethodStore, // 支払方法
    mcloudCommonStore, // Mcloud 共通系
    mcloudLinkingStore, // Mcloud アカウント連携
    platformCancelStore, // Platform 退会
    contactsStore, // Platform 退会
    eMansionEntryStore, // e-mansion 新規会員登録
    fiveACommonStore, // five-a 共通系
    fiveAEntryStore, // five-a 新規会員登録
    fiveAConnectixEntryStore, // five-a Connectix お申し込み
    maintenanceStore, // メンテ用
    maintenanceScreenStore, // メンテナンス画面用
    eMansionAccountStore, // e-mansion Mailアカウント情報
    eMansionMailStore, // e-mansion メール基盤
    permissionStore, // 共通情報 サービス別認可マスタ
    servicePlanTypeStore, // 共通情報 会員種別
    oemsStore, // oem マスタ Store用
    eMansionMainMailAddressStore, // e-mansion メインメール
    eMansionMailPasswordStore, //e-mansion_メールパスワード変更
    eMansionHomepageCapacityStore, //e-mansion_ホームページ容量
    ucomAccountStore, // UCOM アカウント
    ucomAccountManagementStore, // UCOM アカウントID管理
    ucomAccountManagementDeleteStore, // UCOM アカウントID管理 削除 確認
    ucomAccountOptionStore, // UCOM アカウントIDオプション変更
    ucomMailboxCapacityStore, // UCOM メールボックス容量
    eMansionSubAccountStore, //e-mansion追加アカウント
    eMansionHomepageOpenStore, // e-mansion ホームページ開設
    eMansionErrorDisplayInfoStore, // e-mansion エラー画面情報
    taxStore, //消費税
    ucomMailPackStore, //UCOMメールパック
    ucomAccountManagementInputStore, // UCOM アカウントID管理入力
    ucomVirusCheckStore, // UCOM ウイルスチェック
    userScreenTransitionStore, // ユーザ画面遷移追跡用
    ucomWebSpaceStore, // UCOM Webスペース
    errorStore, // エラー画面
    ucomWebSpaceRegisterStore, // UCOM Webスペース登録
    tsunaguOnlineProductHtmlDisplayOnPcStore,
    tsunaguOnlineProductHtmlDisplayOnSpStore,
    invoiceHistoryListDtoStore, // 請求履歴一覧取得
    hasConnectixSubjectToCancelStore, // 解約対象Connectix有無
    productCancelResultStore, // 商品解約結果
    contractsSubjectToCancelStore, //解約対象契約
    productsStore, // 商品
    platformProductEntryStore, // サービス契約
    gamewithMemberEditStore, //GW光登録情報変更
    ucomRentalServiceStore, // UCOM 機器レンタルサービス
    errorMessageStore, //エラーメッセージ
  },
});
