<template>
  <div class="products-cancel-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>サービス解約お申し込み 確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li>サービス解約お申し込み確認</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="サービス解約お申し込み" />ご解約お申し込み</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-if="errorMessages.length" v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
      </div>
      <div>
        下記商品のご解約お申し込みを行います<br />
        お申し込み内容をご確認のうえ、お手続きをお願いいたします。
      </div>
      <div class="blc">
        <div>解約お申し込みサービス名： <span v-html="concatenatedSubscriptionNames"></span></div>
        <div>ご契約状況及びご請求金額につきましては、マイページ内からご確認をお願いいたします。</div>
      </div>

      <div class="blc">
        <div class="toBold red">解約に関する注意事項</div>
        <div class="red">
          本サービスを解約する事により「Portasサービス」内のご契約サービスが１つのみとなった場合、契約中サービスは<span class="toBold">セット料金から通常料金に変更</span
          >となります。<br />
          U-NEXTの場合は<span class="toBold">Portasセット値引きが適用外</span>となります。
        </div>
      </div>

      <div class="blc">
        <label><input type="checkbox" v-model="isAgreeWarning" /> <b>解約に関する注意事項に同意して解約する</b></label>
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()">戻る<i class="material-icons link link-icon">west</i></button>
          <button class="btn btn01 bs" type="button" v-on:click="onNextServiceCancelComplete()" :class="buttonColorSet" :disabled="!activeApplyButton">
            解約<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { SpfApiService } from '../../../../shared/services/api/spf-api-service';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { SubscriptionDetailsDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { SubscriptionDetailsRequestDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-request-dto';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    member: null as Member | null,
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** 表示用サブスクリション名 */
    dispSubscriptionNameList: [] as string[],
    /** 解約対象契約情報 */
    contractsSubjectToCancelInfoList: [] as SubscriptionDetailsDto[],
    /** 解約対象契約情報 */
    contractsSubjectToCancelInfoListRequest: [] as SubscriptionDetailsRequestDto[],
    /** 解約対象Connectix有無 */
    hasConnectix: false,
    /** 解約に関する注意事項に同意する */
    isAgreeWarning: false,
    /** 商品IDが存在しないサービス名の配列 */
    notExistIdProductNameArray: [] as string[],
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 会員情報をStoreから取得
    this.member = this.$store.getters['memberStore/member'];

    // 解約対象契約ストア から 解約対象契約情報を取得する
    this.contractsSubjectToCancelInfoList = this.$store.getters['contractsSubjectToCancelStore/products'];

    // contractsSubjectToCancelInfoList が Object以外の時は、商品ID以外だとみなす
    this.contractsSubjectToCancelInfoList.filter((value) => {
      if (value instanceof Object) {
        return;
      } else {
        this.notExistIdProductNameArray.push(value);
        return;
      }
    });
    // Connectix解約要否取得処理
    this.hasConnectix = this.$store.getters['hasConnectixSubjectToCancelStore/hasConnectix'];
    this.isLoading = false;
  },
  methods: {
    /**戻るボタン */
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**
     * 解約ボタン
     * 押下すると商品解約 > 完了 画面へ遷移
     */
    async onNextServiceCancelComplete() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      try {
        if (this.member?.id) {
          // サーバー現在時刻取得 APIからサーバー側の現在日時を取得
          const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

          // 解約申込日時
          const cancellationAppliedAt: string = dayjs(serverDateTimeNow).format('YYYY-MM-DDTHH:mm:ssZ');
          // 解約対象契約配列 作成
          for (const Info of this.contractsSubjectToCancelInfoList) {
            if (Info instanceof Object) {
              this.contractsSubjectToCancelInfoListRequest.push(
                new SubscriptionDetailsRequestDto({
                  contractId: Info.contractId,
                  contentProviderId: Info.contents.contentProviderId,
                  requestDateTime: dayjs(Info.requestDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
                })
              );
            }
          }
          // サブスクリプション解約申込 API 実行処理
          const productCancelResult = await SpfApiService.subscriptionCancellationRequest(
            this.member?.id.toString(),
            this.contractsSubjectToCancelInfoListRequest,
            cancellationAppliedAt,
            this.notExistIdProductNameArray
          );

          // 「商品解約結果」ストアへの解約結果格納処理
          this.$store.commit('productCancelResultStore/productCancelResult', productCancelResult);
        }
      } catch (error: any) {
        // リクエストボディ.解約申込日時の日付と「サブスクリプション解約申込 API」実行日の差異が原因でリクエストボディの検証処理に失敗した場合
        if (error.response.data.errors[0] === 'cancellationAppliedAt is not current day') {
          // 初回クリック時のみerrorMessages.pushをする
          if (this.errorMessages.length === 0) {
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください';
            this.errorMessages.push('サービス解約確認画面の滞在中に日付が変わりました。<br>解約予定日が変更された可能性があるため、もう一度解約情報をご確認ください。');
          }
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        } else if (error.response.data.errors[0] === 'requestDateTime is same day with cancellationAppliedAt') {
          // 初回クリック時のみerrorMessages.pushをする
          if (this.errorMessages.length === 0) {
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください';
            this.errorMessages.push('U-NEXTは、お申し込み当日の解約はできません。');
          }
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
        throw error;
      } finally {
        // ボタン押下中扱いを解除
        this.isSubmitting = false;
      }

      // 商品解約 > 完了 画面へ遷移
      if (this.isAgreeWarning) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/my-page/products/cancel-completed').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
  },
  computed: {
    buttonColorSet() {
      if (this.isAgreeWarning) {
        return 'btn btn01 bs';
      }
      return 'btn btn04 bs';
    },
    activeApplyButton(): boolean {
      if (this.isAgreeWarning) {
        return true;
      }
      return false;
    },
    /**
     * 契約中または解約対象の商品の商品名を「+」で連結して返す。
     * contractsSubjectToCancelInfoList が文字列（"Connectix"）を含む場合、それも連結する。
     *
     * @returns 連結された商品名の文字列
     */
    concatenatedSubscriptionNames(): string {
      const productNames = this.contractsSubjectToCancelInfoList.map((contract) => {
        if (typeof contract === 'object') {
          return contract.products.productsName;
        } else if (typeof contract === 'string') {
          return contract;
        }
      });
      return productNames.join(' + ');
    },
  },
});
</script>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.toBold {
  font-weight: bold;
}

button.btn01:disabled {
  opacity: 0.5;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>
