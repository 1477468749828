<template>
  <div class="coupon-and-campaign-at-top-wrapper">
    <div class="coupon-and-campaign-at-top-outer">
      <section class="coupon-and-campaign-at-top">
        <div class="my-title">
          <h2>クーポン・キャンペーン</h2>
        </div>

        <div class="my-lead-sentence">
          <p>Portas会員の方にご利用いただけるクーポンをご提供しています。</p>
          <p>クーポンコード、プロモーションコードは、Portasにログインまたは新規会員登録後、ログイン後のページに表示されます。</p>
        </div>
        <div class="flex-pc">
          <div class="my-campaign-image" v-for="image in campaignImageList" :key="image.url">
            <img :src="`${image.pc}`" class="img-pc" />
            <img :src="`${image.sp}`" class="img-sp" />
          </div>

          <div class="my-campaign-image" v-for="image in tsunaguOnlineShopProductImageList" :key="image.url">
            <a :href="`${image.url}`" class="fetch-display-html">
              <img :src="`${image.pc}`" class="img-pc" />
              <img :src="`${image.sp}`" class="img-sp" />
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.coupon-and-campaign-at-top-wrapper {
  background-color: #efefef;
  padding-bottom: 24px;
}

section.coupon-and-campaign-at-top {
  padding: 40px 16px;
  & div.my-title {
    margin-bottom: 24px;
    & h2 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
      line-height: 1.6;
      border-bottom: 3px solid #cf1225;
    }
  }
  & div.my-lead-sentence {
    margin-bottom: 24px;
    & p {
      margin-bottom: 4px;
      line-height: 1.6;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

div.my-campaign-image {
  margin: 0 5px 20px 5px;
  pointer-events: none;
  & img {
    width: 100%;
    &.img-pc {
      display: block;
      pointer-events: auto;
    }
    &.img-sp {
      display: none;
      pointer-events: auto;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.flex-pc {
  display: flex;
}

@media only screen and (max-width: 767px) {
  div.my-campaign-image {
    text-align: center;
    pointer-events: none;
    & img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      &.img-sp {
        display: block;
        pointer-events: auto;
      }
      &.img-pc {
        display: none;
      }
    }
  }
  .flex-pc {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  section.coupon-and-campaign-at-top {
    & div.my-title {
      & h2 {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  div.coupon-and-campaign-at-top-outer {
    max-width: 960px;
    margin: 0 auto;
  }

  section.coupon-and-campaign-at-top {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_LOGGED_IN, TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_NOT_LOGGED_IN } from '@/shared/const/platform-image';

export default defineComponent({
  name: 'coupon-and-campaign-at-top',
  props: {
    passEventToParentComponent: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    campaignImageList: PORTAS_SERVICE_IMAGE_AT_TOP_CAMPAIGN_LOGGED_IN,
    tsunaguOnlineShopProductImageList: TSUNAGU_ONLINE_SHOP_PRODUCT_IMAGE_LIST_AT_NOT_LOGGED_IN,
  }),
  mounted() {
    this.$nextTick(() => {
      [...document.getElementsByClassName('fetch-display-html')].map((element) => {
        return element.addEventListener('click', this.passEventWrapper);
      });
    });
  },
  methods: {
    /**
     * swiperの詳しく見るボタンをクリックされたときのイベントを渡す
     */
    passEventWrapper(e: Event): void {
      this.passEventToParentComponent(e);
    },
  },
});
</script>
