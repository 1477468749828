<template>
  <div class="confirm">
    <LoadingComponent v-if="isSubmitting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portas新規会員登録・サービスお申し込み最終確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li v-if="!isGameWith"><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid5 gap10">
        <li>お申し込み受付</li>
        <li>お客様情報入力</li>
        <li>Portas会員限定 お得なサービスのご案内</li>
        <li class="stay">Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:新規利用者登録 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お客様情報確認" />お客様情報確認</h2>
        <ErrorMessagesComponent v-if="errorMessages.length > 0" :error-message-title="errorMessageTitle" :error-messages="errorMessages" />

        <table class="table-type2 table_layout" v-if="form">
          <tr>
            <th class="va-middle">お名前</th>
            <td>{{ form.firstName }} {{ form.lastName }}</td>
          </tr>
          <tr>
            <th class="va-middle">フリガナ</th>
            <td>{{ form.firstNameKana }} {{ form.lastNameKana }}</td>
          </tr>
          <tr>
            <th class="va-middle">メールアドレス</th>
            <td class="mail">{{ form.mailAddress }}</td>
          </tr>
          <tr>
            <th>生年月日</th>
            <td>
              {{ form.birthYear }}年{{ form.birthMonth }}月{{ form.birthDay }}日
              <p class="att red mt10">※生年月日は一度登録すると後から変更はできません。</p>
            </td>
          </tr>
        </table>
        <!--GW光の場合お住いの建物エリアは表示しない-->
        <div v-if="!isGameWith">
          <!-- 会員ステータス.遷移元サービス情報から所得した物件が存在するとき -->
          <template v-if="propertyFromTransitionSourceServiceId">
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>お住まいの建物</th>
                  <td>
                    <p class="font18px mb10">
                      <b>{{ propertyFromTransitionSourceServiceId.dispApartmentName }}</b>
                    </p>
                    <p>〒{{ propertyFromTransitionSourceServiceId.zipcode }} {{ propertyFromTransitionSourceServiceId.address1 }}</p>
                    <p class="att red mt10">
                      ※表示されている建物名・ご住所にお間違いがないか確認をお願いいたします。<br />
                      （お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。）<br />
                      お住まいの建物情報はご登録後変更いただくことができません。
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <!-- /platform/entry/input にて、「お住まいを登録する」をクリックして物件選択したとき -->
          <template v-else-if="selectedProperty">
            <table class="table-type2 border-top-none">
              <tr>
                <th>お住まいの建物</th>
                <td>
                  <p class="font18px mb10">
                    <b>{{ selectedProperty.apartmentNameDisplay }}</b>
                  </p>
                  <p>{{ selectedProperty.apartmentAddress }}</p>
                  <p class="att red mt10">
                    ※表示されている建物名・ご住所にお間違いがないか確認をお願いいたします。<br />
                    （お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。）<br />
                    お住まいの建物情報はご登録後変更いただくことができません。
                  </p>
                </td>
              </tr>
            </table>
          </template>
        </div>
        <table class="table-type2 table_layout border-top-none" v-if="form?.address || (form?.zipcode1 && form?.zipcode2) || form?.apartmentBlockName || form?.roomNumber">
          <tr>
            <th class="va-middle">Portasサービス契約住所</th>
            <td>
              <p>〒 {{ form.zipcode1 }}-{{ form.zipcode2 }}</p>
              <p>{{ form.address }}</p>
              <p>{{ form.apartmentBlockName }}</p>
              <p>{{ form.roomNumber }}</p>
              <div class="red mt10">
                <p class="att">※登録した住所は、Portasマイページでの修正ができません。修正をご希望の場合は、お手数ですが、Portasお問い合わせフォームからご連絡ください。</p>
                <p class="att">※ご契約サービスに関するご案内やご請求時の不備等においてメールでご連絡が行えない場合、書面を発送する場合がございますのであらかじめご了承ください。</p>
              </div>
            </td>
          </tr>
        </table>
        <!-- /platform/entry/select-portas-service にて、商品を選択して遷移してきたとき -->
        <template v-if="form && form.wishToContractProductIdList && form.wishToContractProductIdList.length > 0">
          <PortasServiceApplicationControllerAtPlatformEntryConfirm
            ref="portasServiceApplicationControllerAtPlatformEntryConfirm"
            v-on:card-token-for-register="receiveCardTokenForRegister"
            v-on:card-token-for3d-secure-authorize="receiveCardTokenFor3dSecureAuthorize"
          />
        </template>

        <!-- /blc -->

        <!-- 支払方法登録APIがエラーになり、 /platform/select-portas-service 戻って、 サービス申込のチェックを外しているとき -->
        <template v-if="memberGetAtBeforeEach && memberGetAtBeforeEach.id && form && form.wishToContractProductIdList && form.wishToContractProductIdList?.length === 0">
          <div class="blc">
            <p class="form-btn-txt">上記の内容でPortas会員登録が完了しています。</p>
            <p class="form-btn-txt">「トップページへ」を押してPortasをご利用ください。</p>
            <div class="btn-area">
              <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
              <button class="btn btn01" @click="goPlatform()">トップページへ<i class="material-icons link link-icon">east</i></button>
            </div>
          </div>
        </template>

        <!-- 支払方法登録APIがエラーになり、 ページ遷移せず、再度サービス申込を実施するとき -->
        <template
          v-else-if="
            isFailedExecuteRegisterPaymentMethod ||
            (memberGetAtBeforeEach && memberGetAtBeforeEach.id && form && form.wishToContractProductIdList && form.wishToContractProductIdList?.length > 0)
          "
        >
          <div class="blc">
            <p class="form-btn-txt">サービスにお申し込みいただく場合は、「お申し込み」ボタンを押してください。</p>
            <div class="btn-area">
              <div class="my-checkbox-outer">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
                <p class="my-checkbox-text"><label for="checkbox-to-agree">クレジットカード番号および本人認証に必要な情報の提供に同意します。 </label></p>
              </div>
              <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
              <button class="btn" :class="canApply && isAgreeTerms ? 'btn01' : 'btn04'" @click="onSignUp()" :disabled="!canApply">
                お申し込み<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="blc" v-if="!(form && form.wishToContractProductIdList && form.wishToContractProductIdList.length > 0)">
            <p class="form-btn-txt">ご入力いただいたお客様情報にお間違えがないかご確認ください。</p>
            <p class="form-btn-txt">お客様情報に誤りがございましたら「戻る」ボタンを押して修正してください。</p>
            <p class="form-btn-txt">Portas新規会員登録いただく場合には、「Portas新規会員登録の確定」ボタンを押してください。</p>
            <p class="form-btn-txt">再度、Portas利用規約、個人情報の取り扱いについて確認したい場合、下記リンクよりご確認ください。</p>
            <div class="btn-area">
              <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
              <button class="btn mt-sp-1" :class="canApply ? 'btn01' : 'btn04'" @click="onSignUp()" :disabled="!canApply">
                Portas新規会員登録の確定<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
          <!--サービスを申し込む場合-->
          <div class="blc" v-if="form && form.wishToContractProductIdList && form.wishToContractProductIdList.length > 0">
            <p class="form-btn-txt">ご入力いただいたお客様情報にお間違えがないかご確認ください。</p>
            <p class="form-btn-txt">お客様情報に誤りがございましたら「戻る」ボタンを押して修正してください。</p>
            <p class="form-btn-txt">お申し込みいただくサービスの条件をご確認ください。</p>
            <p class="form-btn-txt">サービスの条件にご同意いただけない場合には「戻る」ボタンを押してください。</p>
            <p class="form-btn-txt">Portas新規会員登録と上記Portasサービスを、共にお申し込みいただく場合には、「Portas新規会員登録とお申し込みの確定」ボタンを押してください。</p>
            <p class="form-btn-txt">再度、Portas利用規約、各種サービスの利用規約、個人情報の取り扱いについて確認したい場合、下記リンクよりご確認ください。</p>

            <div class="btn-area">
              <div class="my-checkbox-outer">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
                <p class="my-checkbox-text"><label for="checkbox-to-agree">クレジットカード番号および本人認証に必要な情報の提供に同意します。</label></p>
              </div>
              <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
              <button class="btn mt-sp-1" :class="canApply && isAgreeTerms ? 'btn01' : 'btn04'" @click="onSignUp()" :disabled="!(canApply && isAgreeTerms)">
                Portas新規会員登録とお申し込みの確定<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
        </template>
      </div>
      <!-- /contents -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* メールアドレス */
.table_layout {
  table-layout: fixed;
}

.mail {
  overflow-x: auto;
}

.border-top-none {
  border-top: none;
}

.my-checkbox-outer {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

@media only screen and (max-width: 767px) {
  button.mt-sp-1 {
    margin: 10px 5px 0 5px;
  }
}
</style>

<script lang="ts">
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import PortasServiceApplicationControllerAtPlatformEntryConfirm from '../components/portas-subscription/portas-service-application-controller-at-platform-entry-confirm.vue';

import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { UpdateGamewithCustomerRequest } from '@/infra/request/spf/gamewith/update-gamewith-customer-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { ExternalSearchPropertiesPropertyInfoDto } from '@/shared/classes/external-api/search-properties/external-search-properties-property-info-dto';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getPropertyIdOnPortasDB } from '@/shared/util/func-get-property-id-on-portas-db';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { InputPropertyForm } from '@/store/platform/platform-edit-store';

type DataType = {
  /** エラーメッセージを格納する配列 */
  errorMessages: string[];
  errorMessageTitle: string;
  selectedProperty: ExternalSearchPropertiesPropertyInfoDto | null | undefined;
  propertyFromTransitionSourceServiceId: Property | null;
  form: PlatformEntryInputForm | null;
  inputPropertyForm: InputPropertyForm | null;
  /** beforeEachの処理により取得された Portas会員 */
  memberGetAtBeforeEach: Member | null;
  isSubmitting: boolean;
  cardTokenForRegister: {
    token: string;
    tokenExpireDate: string;
  };
  cardTokenFor3dSecureAuthorize: {
    token: string;
    tokenExpireDate: string;
  };
  /** 支払方法登録APIに失敗したとき true になる */
  isFailedExecuteRegisterPaymentMethod: boolean;
  isGameWith: boolean;
  encryptedPrimaryKeyGw: string | undefined;
  primaryKeyGamewith: string | undefined;
  isAgreeTerms: boolean;
};

/** Terms コンポーネント */
export default defineComponent({
  name: 'platform-entry-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    PortasServiceApplicationControllerAtPlatformEntryConfirm,
  },
  data(): DataType {
    return {
      errorMessages: [] as string[],
      errorMessageTitle: '',
      selectedProperty: null,
      propertyFromTransitionSourceServiceId: null,
      form: null,
      inputPropertyForm: null,
      memberGetAtBeforeEach: null,
      isSubmitting: false,
      cardTokenForRegister: {
        token: '',
        tokenExpireDate: '',
      },
      cardTokenFor3dSecureAuthorize: {
        token: '',
        tokenExpireDate: '',
      },
      isFailedExecuteRegisterPaymentMethod: false,
      isGameWith: false,
      encryptedPrimaryKeyGw: undefined,
      primaryKeyGamewith: undefined,
      isAgreeTerms: false,
    };
  },
  async mounted() {
    this.form = this.$store.getters['platformEntryStore/entryInputForm'];
    // リロードによりストアが初期化されていたら入力画面に飛ばす
    if (!this.form || !this.form.firstName) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    this.inputPropertyForm = this.$store.getters['platformEditStore/inputPropertyForm'];
    this.selectedProperty = this.inputPropertyForm?.selectedProperty;
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    if (memberStatus.encryptedPrimaryKeyGw) {
      this.encryptedPrimaryKeyGw = memberStatus.encryptedPrimaryKeyGw;
      this.isGameWith = true;
    }
    this.propertyFromTransitionSourceServiceId = this.$store.getters['platformEntryStore/propertyFromTransitionSourceServiceId'];
    this.memberGetAtBeforeEach = this.$store.getters['memberStore/member'];

    // VeriTrans 本人認証失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
      // エラーになったとき、クレジットカードコンポーネントを再描画する
      const component = this.$refs.portasServiceApplicationControllerAtPlatformEntryConfirm as InstanceType<typeof PortasServiceApplicationControllerAtPlatformEntryConfirm>;
      if (component) {
        // コンポーネントの破棄・再描画のために key値 を変更する
        component.addResetCounter();
        this.cardTokenForRegister = {
          token: '',
          tokenExpireDate: '',
        };
        this.isFailedExecuteRegisterPaymentMethod = true;
      }
    }
  },
  methods: {
    async onSignUp() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // /platform/entry/select-portas-service にて商品を選択して遷移してきたとき、クレジットカード本人認証を行う
      if (this.form && this.form.wishToContractProductIdList && this.form.wishToContractProductIdList.length > 0) {
        const createdContract = await this.contractFromEntryPortas();

        this.form.contractedProductIdList = createdContract;
        this.$store.commit('platformEntryStore/entryInputForm', this.form);

        if (createdContract) {
          await this.$router
            .push('/platform/entry/completed')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
          return;
        }
      } else {
        // 申し込み対象の商品IDが渡され無かった場合、Portas新規会員登録を実行する
        const createdMember = await this.createPortasMember();
        // メールアドレス重複エラーのとき、後続処理を止める
        if (!createdMember) {
          return;
        }
        await AuthService.refresh();

        // 正常ケースの場合
        // バックエンドで会員ステータスが更新される
        // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
        this.$store.commit('memberStore/memberStatus', null);
      }

      //GW光の場合はGW側に連携済みを通知
      if (this.encryptedPrimaryKeyGw) {
        const updateGamewithCustomerRequest = new UpdateGamewithCustomerRequest({ _portasFlag: '登録済み' });
        try {
          await SpfApiExternalGamewithAccessor.updateCustomer(updateGamewithCustomerRequest, this.encryptedPrimaryKeyGw);
        } catch (e: any) {
          //503エラーの場合は入会完了画面へ遷移させる、それ以外の場合は共通エラー
          if (e.response?.status != '503') {
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
          }
        }
      }

      await this.$router
        .push('/platform/entry/completed')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**
     * クレジットカードコンポーネントから渡される、支払方法登録用のクレジットカードトークン情報を保存する
     */
    receiveCardTokenForRegister(token: string, tokenExpireDate: string): void {
      this.cardTokenForRegister = {
        token: token,
        tokenExpireDate: tokenExpireDate,
      };
    },
    /**
     * クレジットカードコンポーネントから渡される、本人認証用のクレジットカードトークン情報を保存する
     */
    receiveCardTokenFor3dSecureAuthorize(token: string, tokenExpireDate: string): void {
      this.cardTokenFor3dSecureAuthorize = {
        token: token,
        tokenExpireDate: tokenExpireDate,
      };
    },
    /**
     * Portas新規会員登録を実行する
     */
    async createPortasMember(): Promise<Member | undefined> {
      if (this.form) {
        const sub = await AuthService.getSub();
        let propertyIdOnPortasDB: number | undefined = undefined;

        if (this.propertyFromTransitionSourceServiceId) {
          propertyIdOnPortasDB = this.propertyFromTransitionSourceServiceId.id;
        } else if (this.selectedProperty) {
          propertyIdOnPortasDB = await getPropertyIdOnPortasDB(this.selectedProperty.apartmentId, this.selectedProperty.uaType);
        }
        if (this.isGameWith) {
          this.primaryKeyGamewith = this.$store.getters['platformEntryStore/primaryKeyGamewith'];
        }
        const member: Member = new Member({
          propertyId: propertyIdOnPortasDB,
          firstName: this.form.firstName,
          givenName: this.form.lastName,
          firstNameKatakana: this.form.firstNameKana,
          givenNameKatakana: this.form.lastNameKana,
          emailAddress: this.form.mailAddress,
          birthdate: `${this.form.birthYear}-${('00' + this.form.birthMonth).slice(-2)}-${('00' + this.form.birthDay).slice(-2)}`,
          address: this.form.address,
          apartmentBlockName: this.form.apartmentBlockName,
          roomNumber: this.form.roomNumber,
          memberStatus: 0,
          primaryKeyAuth0: sub,
          primaryKeyGW: this.primaryKeyGamewith,
        });
        if (!this.form.zipcode1 || !this.form.zipcode2) {
          member.zipcode = '';
        } else {
          member.zipcode = `${this.form.zipcode1}-${this.form.zipcode2}`;
        }

        try {
          // 会員登録処理
          const createdMember = await SpfApiService.createMember(member);

          // あり得ないけど念のため確認
          if (!createdMember) {
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_CREATE_MEMBER);
          }

          return createdMember;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (axios.isAxiosError(e)) {
            // メールアドレスが重複した場合、 /platform/entry/input に遷移してエラーメッセージ表示
            if (e.response?.status === 409) {
              this.form.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
              this.$store.commit('platformEntryStore/entryInputForm', this.form);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              await this.$router.push('/platform/entry/input').catch((error: any) => {
                checkRouterError(error);
              });
              return;
            }
            throw e;
          } else {
            throw e;
          }
        }
      }
    },
    /**
     * サービス同時申込時、本人認証APIを実行する
     */
    async contractFromEntryPortas(): Promise<number[] | undefined> {
      //エラーメッセージをリセット
      this.errorMessages = [];
      this.errorMessageTitle = '';

      if (!(this.cardTokenForRegister && this.cardTokenFor3dSecureAuthorize)) {
        // クレジットカードトークンが発行されていない場合はエラー
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        this.isSubmitting = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }
      if (this.form) {
        const sub = await AuthService.getSub();
        let propertyIdOnPortasDB: number | undefined = undefined;
        if (this.propertyFromTransitionSourceServiceId) {
          propertyIdOnPortasDB = this.propertyFromTransitionSourceServiceId.id;
        } else if (this.selectedProperty) {
          propertyIdOnPortasDB = await getPropertyIdOnPortasDB(this.selectedProperty.apartmentId, this.selectedProperty.uaType);
        }
        if (this.isGameWith) {
          this.primaryKeyGamewith = this.$store.getters['platformEntryStore/primaryKeyGamewith'];
        }
        const member: Member = new Member({
          propertyId: propertyIdOnPortasDB,
          firstName: this.form.firstName,
          givenName: this.form.lastName,
          firstNameKatakana: this.form.firstNameKana,
          givenNameKatakana: this.form.lastNameKana,
          emailAddress: this.form.mailAddress,
          birthdate: `${this.form.birthYear}-${('00' + this.form.birthMonth).slice(-2)}-${('00' + this.form.birthDay).slice(-2)}`,
          zipcode: `${this.form.zipcode1}-${this.form.zipcode2}`,
          address: this.form.address,
          apartmentBlockName: this.form.apartmentBlockName,
          roomNumber: this.form.roomNumber,
          memberStatus: 0,
          primaryKeyAuth0: sub,
          primaryKeyGW: this.primaryKeyGamewith,
        });
        if (this.form.wishToContractProductIdList) {
          //申込情報を一時保存する
          const applicationDataJson = JSON.stringify({
            member: member,
            form: this.form,
            cardTokenForRegister: this.cardTokenForRegister,
            primaryKeyGamewith: this.primaryKeyGamewith,
            propertyFromTransitionSourceServiceId: this.propertyFromTransitionSourceServiceId,
            selectedProperty: this.selectedProperty,
          });
          // UUIDを生成してローカルストレージにも保存
          const uuidForTemporarySavingApplicationData = uuidv4();
          const inputData = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: applicationDataJson,
            subsequentProcess: 'service-entry-regist-member',
          });
          try {
            await SpfApiService.temporarySavingApplicationData(inputData);
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          //3Dセキュア本人認証API
          const secureAuthRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
            uuid: uuidForTemporarySavingApplicationData,
            creditCardToken: this.cardTokenFor3dSecureAuthorize.token,
          });
          try {
            const url = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(secureAuthRequest);
            //本人認証画面へ遷移
            location.href = url;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認のいただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
              this.cardTokenForRegister.token = '';
              this.cardTokenFor3dSecureAuthorize.token = '';
              // エラーになったとき、クレジットカードコンポーネントを再描画する
              const component = this.$refs.portasServiceApplicationControllerAtPlatformEntryConfirm as InstanceType<
                typeof PortasServiceApplicationControllerAtPlatformEntryConfirm
              >;
              if (component) {
                // コンポーネントの破棄・再描画のために key値 を変更する
                component.addResetCounter();
                this.cardTokenForRegister = {
                  token: '',
                  tokenExpireDate: '',
                };
                this.isFailedExecuteRegisterPaymentMethod = true;
              }
              //チェックボックスをリセット
              this.isAgreeTerms = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
        } else {
          throw new Error('お申し込み対象のサービスが存在しません');
        }
      }
    },
    async goPlatform(): Promise<void> {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((e: any) => {
        checkRouterError(e);
      });
      this.isSubmitting = false;
    },
  },
  computed: {
    canApply(): boolean {
      if (this.form && this.form.wishToContractProductIdList && this.form.wishToContractProductIdList.length > 0) {
        if (!this.cardTokenForRegister.token || !this.cardTokenForRegister.tokenExpireDate) {
          return false;
        }
        if (!this.cardTokenFor3dSecureAuthorize.token || !this.cardTokenFor3dSecureAuthorize.tokenExpireDate) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
  },
});
</script>
