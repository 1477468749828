<template>
  <div class="five-a-connectix-completed">
    <LoadingComponent v-if="isExecutingNavigateToLocation" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み完了</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お申し込み完了</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>サービスお申し込み</li>
        <li>お申し込み内容の確認</li>
        <li class="stay">お申し込み完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix　お申し込み"/>Connectix お申し込み完了</h2>
        <p>
          お申し込みありがとうございます。<br />
          <span class="att red">最大10分以内に本サービスが適用されます。
          </span><br />サービスの適用状況は「Five.A会員専用トップ＞ご利用状況確認」からご確認ください。
        </p>
        <p class="mt20">
          Connectixでより快適なインターネットライフをお楽しみください。
        </p>
        <img
          src="../../../images/image.png"
          alt="お申し込み完了"
          class="img-center img-complete"
        />
      </div>

      <div class="blc">
        <div class="btn-area">
          <button type="button" class="btn-height btn btn01 bs" @click="navigateToLocation('/5a')">Five.A 会員専用トップへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>


/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url("../../../images/logo_fivea.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}
.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from "@/shared/util/router-navigation-func";

/** Confirm コンポーネント */
export default defineComponent({
  name: "five-a-connectix-completed",
  components: {
    LoadingComponent
  },
  data: () => ({
    // navigateToLocation() 実行中かどうか
    isExecutingNavigateToLocation: false
  }),
  methods: {
    async navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      await this.$router
        .push(path)
        .catch((error) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    }
  }
});
</script>
