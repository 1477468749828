<template>
  <div class="ucom-connectix-completed">
    <LoadingComponent v-if="isRouting || isMounting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み 完了</h1>
    </main>

    <div class="contents">
    
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス Connectix お申し込み</li>
        <li>お申し込み完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:Connectix　確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix&thinsp;お申し込み完了</h2>
        <p>お申し込みありがとうございます。<br />数分程度でサービスをご利用いただけます。</p>
        <p class="mt20">Connectixでより快適なインターネットライフをお楽しみください。</p>
        <img src="../../../images/image.png" alt="お申し込み完了" class="img-center img-complete" />
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button type="button" class="btn-height btn btn01 bs" v-on:click="onNext()">
            UCOM光 レジデンス 会員専用トップ&thinsp;<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->

    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.img-complete {
  width: 320px;
  max-width: 60%;
}

.btn-area {
  margin: 30px auto 0;
  text-align: center;
}
.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url("../../../images/logo-ucom.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}

.connectix-campaign-title{
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign{
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** UCOM光レジデンス Connectixお申し込み 完了画面 */
export default defineComponent({
  name: 'ucom-connectix-completed',
  components: {
    LoadingComponent
  },
  data: () => ({
    isRouting: false, // router.pushが実行されるまで用
    isMounting: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    this.$data.isMounting = true;
    // 入力値を保持しているストアの削除
    this.$store.commit('ucomConnectixEntryStore/entryInputForm', null);
    this.$data.isMounting = false;
  },
  methods: {
    async onNext() {
      if (this.isRouting) {
        return;
      }
      this.isRouting = true;

      await this.$router
        .push('/ucom')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isRouting = false;
        });
    }
  }
});
</script>
