<template>
  <div class="blc">
    <LoadingComponent v-if="isSubmitting" />
    <h2 class="portal-h2 cf">Portasサービス</h2>
    <p>快適なインターネットライフを支えるPortas会員様だけのお得なサブスクサービスです。</p>
    <p>お客様のニーズに応じたお得なセットもご用意しております。</p>
    <p>初回お申し込み時は無料期間が適用されお試しいただけます。</p>
    <a :href="portasServiceFreePeriodChangeNoticeUrl" target="_blank" class="red link"><b>【重要】Portasサービスの無料期間変更のお知らせ</b></a>

    <h3 class="mt10 mb10">ご希望のサービスにチェックをして【お申し込み内容のご確認に進む】ボタンを押してください。</h3>

    <!-- ISPと紐づけ済み かつ Connectix対象物件の場合。 -->
    <div class="table-type4 js-scrollable table-scroll" v-if="isProvidedConnectix && isLinkedIsp">
      <table class="border-grey">
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.tsunagunet.com/connectix/c-basic/" target="_blank">Connectix</a>
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.arteria-net.com/portas/service/u-next?utm_source=Portas&utm_medium=menu&utm_campaign=u-next&utm_content=matrixmenu_portop" target="_blank"
                    >U-NEXT</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/norton/" target="_blank"
                    >ノートン&trade;<br />
                    セキュリティ</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/sagiwall/" target="_blank"
                    >Internet SagiWall <br />
                    for マルチデバイス</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/dsup/" target="_blank"
                    >Wi-Fi＆Device <br />
                    Support</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.arteria-net.com/portas/service/adguard?utm_source=Portas&utm_medium=menu&utm_campaign=adguard&utm_content=matrixmenu_portop" target="_blank"
                    >AdGuard
                  </a>
                </div>
              </div>
            </th>
            <th>月額料金<br />（税込）</th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ disabled: !canApplyConnectixFlag || isInCancelApplication || isContractedProduct4 }">
            <td class="red toBold">
              <input id="CanConnectixSetC4" type="radio" name="subscription-set" @change="radioSelected('connectix,4')" :value="radioSelectedValueModel" />
              <label for="CanConnectixSetC4">U-NEXT for Portas+Connectix<br />（Portasセット値引き220円（税込））</label>
            </td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(2949) }}円</td>
          </tr>
          <tr :class="{ disabled: !canApplyConnectixFlag || isInCancelApplication }">
            <td class="red toBold">
              <input id="CanConnectixProductC" type="radio" name="subscription-set" @change="radioSelected('connectix')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProductC">Connectix</label>
            </td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(980) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct4 }">
            <td class="red toBold">
              <input id="CanConnectixProduct4" type="radio" name="subscription-set" @change="radioSelected('4')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct4">U-NEXT for Portas</label>
            </td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(2189) }}円</td>
          </tr>

          <!-- 
            MEMO:
            既にConnectix利用中 or 退会予定者 には表示しない 条件を付けたい時↓
            <tr :class="{ disabled: !canApplyConnectixFlag || isInCancelApplication }">
          -->
          <tr :class="{ disabled: !canApplyConnectixFlag || isInCancelApplication || isContractedProduct1 || isContractedProduct2 || isContractedProduct3 }">
            <td class="red toBold">
              <input id="CanConnectixSetC123" type="radio" name="subscription-set" @change="radioSelected('connectix,1,2,3')" :value="radioSelectedValueModel" />
              <label for="CanConnectixSetC123">インターネット環境快適セット</label>
            </td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td>{{ formatMoney(2212) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 || isContractedProduct3 || isContractedProduct5 }">
            <td class="red toBold">
              <input id="CanConnectixSet1235" type="radio" name="subscription-set" @change="radioSelected('1,2,3,5')" :value="radioSelectedValueModel" />
              <label for="CanConnectixSet1235">あんしんサポートセットプラス</label>
            </td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td>{{ formatMoney(1496) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 || isContractedProduct3 }">
            <td class="red toBold">
              <input id="CanConnectixSet123" type="radio" name="subscription-set" @change="radioSelected('1,2,3')" :value="radioSelectedValueModel" />
              <label for="CanConnectixSet123">あんしんサポートセット</label>
            </td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td>{{ formatMoney(1232) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 }">
            <td class="red toBold">
              <input id="CanConnectixSet12" type="radio" name="subscription-set" @change="radioSelected('1,2')" :value="radioSelectedValueModel" />
              <label for="CanConnectixSet12">あんしんセキュリティセット</label>
            </td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(792) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 }">
            <td class="red toBold">
              <input id="CanConnectixProduct1" type="radio" name="subscription-set" @change="radioSelected('1')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct1">ノートン&trade; セキュリティ</label>
            </td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(627) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct2 }">
            <td class="red toBold">
              <input id="CanConnectixProduct2" type="radio" name="subscription-set" @change="radioSelected('2')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct2">Internet SagiWall for マルチデバイス</label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(330) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct3 }">
            <td class="red toBold">
              <input id="CanConnectixProduct3" type="radio" name="subscription-set" @change="radioSelected('3')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct3">Wi-Fi＆Device Support</label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td>{{ formatMoney(550) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct5 }">
            <td class="red toBold">
              <input id="CanConnectixProduct5" type="radio" name="subscription-set" @change="radioSelected('5')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct5">AdGuard</label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td>{{ formatMoney(330) }}円</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Connectix非対象物件の場合。-->
    <div class="table-type4 js-scrollable table-scroll" v-else>
      <table class="border-grey">
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.arteria-net.com/portas/service/u-next?utm_source=Portas&utm_medium=menu&utm_campaign=u-next&utm_content=matrixmenu_portop" target="_blank"
                    >U-NEXT</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/norton/" target="_blank">
                    ノートン&trade;<br />
                    セキュリティ</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/sagiwall/" target="_blank">
                    Internet SagiWall <br />
                    for マルチデバイス</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.portasservice.arteria-net.com/dsup/" target="_blank">
                    Wi-Fi＆Device <br />
                    Support</a
                  >
                </div>
              </div>
            </th>
            <th>
              <div class="table-header-content">
                <i class="material-icons table-header-content__icon">open_in_new</i>
                <div class="table-header-content__title">
                  <a href="https://www.arteria-net.com/portas/service/adguard?utm_source=Portas&utm_medium=menu&utm_campaign=adguard&utm_content=matrixmenu_portop" target="_blank"
                    >AdGuard</a
                  >
                </div>
              </div>
            </th>
            <th>月額料金<br />（税込）</th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ disabled: isContractedProduct4 }">
            <td class="red toBold">
              <input id="CanConnectixProduct4" type="radio" name="subscription-set" @change="radioSelected('4')" :value="radioSelectedValueModel" />
              <label for="CanConnectixProduct4">U-NEXT for Portas</label>
            </td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(2189) }}円</td>
          </tr>

          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 || isContractedProduct3 || isContractedProduct5 }">
            <td class="red toBold">
              <input id="SetProduct1235" type="radio" name="subscription-set" @change="radioSelected('1,2,3,5')" :value="radioSelectedValueModel" />
              <label for="SetProduct1235">あんしんサポートセットプラス</label>
            </td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td>{{ formatMoney(1496) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 || isContractedProduct3 }">
            <td class="red toBold">
              <input id="SetProduct123" type="radio" name="subscription-set" @change="radioSelected('1,2,3')" :value="radioSelectedValueModel" />
              <label for="SetProduct123">あんしんサポートセット</label>
            </td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td>{{ formatMoney(1232) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 || isContractedProduct2 }">
            <td class="red toBold">
              <input id="SetProduct12" type="radio" name="subscription-set" @change="radioSelected('1,2')" :value="radioSelectedValueModel" />
              <label for="SetProduct12">あんしんセキュリティセット</label>
            </td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(792) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct1 }">
            <td class="red toBold">
              <input id="Product1" type="radio" name="subscription-set" @change="radioSelected('1')" :value="radioSelectedValueModel" />
              <label for="Product1">ノートン&trade; セキュリティ</label>
            </td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(627) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct2 }">
            <td class="red toBold">
              <input id="Product2" type="radio" name="subscription-set" @change="radioSelected('2')" :value="radioSelectedValueModel" />
              <label for="Product2">Internet SagiWall for マルチデバイス</label>
            </td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td></td>
            <td>{{ formatMoney(330) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct3 }">
            <td class="red toBold">
              <input id="Product3" type="radio" name="subscription-set" @change="radioSelected('3')" :value="radioSelectedValueModel" />
              <label for="Product3">Wi-Fi＆Device Support</label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td></td>
            <td>{{ formatMoney(550) }}円</td>
          </tr>
          <tr :class="{ disabled: isContractedProduct5 }">
            <td class="red toBold">
              <input id="Product5" type="radio" name="subscription-set" @change="radioSelected('5')" :value="radioSelectedValueModel" />
              <label for="Product5">AdGuard</label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="red toBold"><i class="material-icons service-check">check</i></td>
            <td>{{ formatMoney(330) }}円</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <ul>
        <li class="toBold">お申し込み月から料金が発生します。<br />（無料期間がある場合は無料期間終了後に料金が発生します。）</li>
        <li>セット料金について：ご請求前月に「Portasサービス」内でご契約サービスが２つ以上ある場合、セット料金が適用されます。</li>
        <li>
          サービスを2つ以上お申し込みいただいていても、ご請求前に「Portasサービス」内の他サービスを全て解約した場合、契約中サービスのご請求金額は通常料金となりますのでご注意ください。<br />
          <span class="red"
            >※ U-NEXT月額料金がご請求対象となり、かつご請求前月に他の「Portasサービス」をご契約中の場合に、Portasセット値引き：▲{{
              uNextDiscountPriceIncludingTax
            }}円（税込）が適用されます。</span
          >
        </li>
      </ul>
    </div>

    <div v-if="isNotExistUserInfoForApplyService" class="err">
      <div class="red">
        Portasサービスをお申し込みいただくには、お名前（フリガナ）、生年月日、Portasサービス契約住所をご登録いただく必要があります。<br />
        Portas会員情報変更よりご登録いただき、お申し込みいただきますようお願いいたします。<br />
        → <router-link class="link" to="/platform/my-page/member-edit">Portas会員情報変更</router-link>
      </div>
    </div>

    <div class="btn-area">
      <button class="btn-height btn btn01 bs" type="button" :disabled="!isRadioValueExist || isNotExistUserInfoForApplyService" @click="onApplicationButton()">
        お申し込み内容のご確認に進む
      </button>
    </div>
    <div class="blc">
      <h3>Portasサービスご契約状況のご確認・解約、ご請求明細、お支払い方法の登録・変更はPortasマイページからお手続きいただけます。</h3>
    </div>
    <div>
      <img class="material-icons link link-icon" src="../../../images/service-h2.svg" />
      <router-link to="/platform/my-page" class="link">Portasマイページへ</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { UA_TYPE } from '../../../shared/const/service-type';

import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionConnectixStatus } from '@/shared/services/e-mansion/e-mansion-connectix-status';
import { FiveAConnectixStatus } from '@/shared/services/five-a/five-a-connectix-status';
import { PortasSubscriptionContract } from '@/shared/services/portas-subscription/portas-subscription-contract';
import { UcomConnectixStatus } from '@/shared/services/ucom/ucom-connectix-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 物件情報 */
    property: null as Property | null,
    /** 退会申し込み済みかどうか */
    isInCancelApplication: false,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** ラジオボタンが選択されているかどうか */
    isRadioValueExist: false,
    radioSelectedValueModel: [] as string[],
    /** ラジオボタンで選択された値1 */
    selectedValue1: '' as string,
    /** ラジオボタンで選択された値2 */
    selectedValue2: '' as string,
    /** ラジオボタンで選択された値3 */
    selectedValue3: '' as string,
    /** ラジオボタンで選択された値4 */
    selectedValue4: '' as string,
    /** ISP紐づけされているかどうか */
    isLinkedIsp: false,
    /** Connectix提供物件かどうか */
    isProvidedConnectix: false,
    /** サブスク商品1が契約済かどうか */
    isContractedProduct1: false,
    /** サブスク商品2が契約済かどうか */
    isContractedProduct2: false,
    /** サブスク商品3が契約済かどうか */
    isContractedProduct3: false,
    /** サブスク商品4(U-NEXT)が契約済かどうか */
    isContractedProduct4: false,
    /** サブスク商品5が契約済かどうか */
    isContractedProduct5: false,
    /** U-NEXTセット割引料金(税込み) */
    uNextDiscountPriceIncludingTax: 220,
    ScrollHint: require('../../../shared/libraries/scroll-hint/scroll-hint'),
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** Portasサービスのお申し込みに必要なユーザー情報が登録されているかどうか */
    isNotExistUserInfoForApplyService: false,
    /** Connectix申込可能判定 */
    canApplyConnectixFlag: false,
    /** Connectixご利用中かどうか */
    isContractedConnectixFlag: false,
    /** 【2024年10月31日まで】Portasサービス 無料期間変更のお知らせPDF URL */
    portasServiceFreePeriodChangeNoticeUrl: process.env.VUE_APP_PORTAS_SERVICE_FREE_PERIOD_CHANGE_NOTICE_URL,
  }),
  async mounted() {
    this.isSubmitting = true;
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];

    if (memberStatus && memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.isInCancelApplication = true;
    }

    // 物件情報取得
    this.property = this.$store.getters['propertyStore/property'];
    const member: Member = this.$store.getters['memberStore/member'];

    // UA種別 の値が 1(e-mansion) のとき
    if (this.property?.uaType === UA_TYPE.E_MANSION) {
      this.isProvidedConnectix = EMansionConnectixStatus.isProvided();
      if (member.primaryKeyMye) {
        this.isLinkedIsp = true;
      }

      // UA種別 の値が 2(Five.A) のとき
    } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
      this.isProvidedConnectix = FiveAConnectixStatus.isProvided();
      if (member.primaryKeyMye) {
        this.isLinkedIsp = true;
      }

      // UA種別 の値が 3(UCOM) のとき
    } else if (this.property?.uaType === UA_TYPE.UCOM) {
      this.isProvidedConnectix = UcomConnectixStatus.isProvided();
      if (member.primaryKeyUcom) {
        this.isLinkedIsp = true;
      }
    }

    this.canApplyConnectixFlag = this.canApplyConnectix();
    this.isContractedConnectixFlag = this.isContractedConnectix();
    this.$nextTick(async () => {
      await this.isContractedProduct();
      this.jsScrollable();
      this.isSubmitting = false;
    });
  },
  methods: {
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
    /**
     * お申し込み ボタン押下時の処理
     */
    async onApplicationButton() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      const isExistUserInfoForApplyPortasService = await this.checkExistUserInfoForApplyPortasService();

      if (!isExistUserInfoForApplyPortasService) {
        this.isNotExistUserInfoForApplyService = true;
        this.isSubmitting = false;
        return;
      }

      //URL作成(選択された商品によって変わる)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const strUrlForProduct = this.radioSelectedValueModel.map((value: any, index: number) => `product${index + 1}=${value}`).join('&');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$router.push(`/platform/my-page/products/entry-input?${strUrlForProduct}`).catch((error: any) => {
        checkRouterError(error);
      });
    },

    /**
     * 選択されているラジオボタンの値セット
     * セットする順番: "Connectix、ノートン&trade; セキュリティ、Internet SagiWall for マルチデバイス、Wi-Fi＆Device Support、U-NEXT"
     * セットする値: 商品コード
     */
    radioSelected(selectedValue: string) {
      // 選択したラジオボタンの値保持
      this.radioSelectedValueModel = selectedValue.split(',');
      this.isRadioValueExist = true;
    },
    /**
     * ユーザーの契約中商品ID(カンマ区切り文字列) から、各商品IDの契約状態を判定して変更する
     */
    async isContractedProduct() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberId: number = member.id;

      const contractedProduct = await PortasSubscriptionContract.getProductIdContracting(memberId);
      if (contractedProduct) {
        const contractedProductArray = contractedProduct.split(',').map((str) => parseInt(str, 10));

        if (contractedProductArray.includes(1)) {
          this.isContractedProduct1 = true;
        }
        if (contractedProductArray.includes(2)) {
          this.isContractedProduct2 = true;
        }
        if (contractedProductArray.includes(3)) {
          this.isContractedProduct3 = true;
        }
        if (contractedProductArray.includes(4)) {
          this.isContractedProduct4 = true;
        }
        if (contractedProductArray.includes(5)) {
          this.isContractedProduct5 = true;
        }
      }
      return;
    },
    jsScrollable() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * 氏名・生年月日未登録のPortasユーザーがサブスク商品を申し込めないよう、チェックを行う
     */
    async checkExistUserInfoForApplyPortasService(): Promise<boolean> {
      const member: Member = await this.$store.dispatch('memberStore/member');

      if (
        !member.firstName ||
        !member.firstNameKatakana ||
        !member.givenName ||
        !member.givenNameKatakana ||
        !member.birthdate ||
        member.birthdate === '0000-00-00' ||
        !member.zipcode ||
        !member.address
      ) {
        return false;
      }
      return true;
    },
    /**
     * Connectixに申し込み可能かどうか
     * 物件.UA種別で場合分けしたいが、呼び出しているメソッド内にて 物件.UA種別 の場合分け実施している
     */
    canApplyConnectix(): boolean {
      return EMansionConnectixStatus.canApply() || FiveAConnectixStatus.canApply() || UcomConnectixStatus.canApply();
    },
    /**Connectix契約中かどうか */
    isContractedConnectix(): boolean {
      if (this.property?.uaType == UA_TYPE.E_MANSION) {
        return EMansionConnectixStatus.isContracted();
      }
      if (this.property?.uaType == UA_TYPE.UCOM) {
        return UcomConnectixStatus.isContracted();
      }
      if (this.property?.uaType == UA_TYPE.FIVE_A) {
        return FiveAConnectixStatus.isContracted();
      }
      return false;
    },
  },
  watch: {
    radioSelectedValueModel: {
      handler(newValue) {
        this.isRadioValueExist = newValue != null;
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.service-check {
  font-size: 2em !important;
}

.toBold {
  font-weight: bold;
}

h2 {
  border-bottom: 3px solid #0097e0;
  display: inline-block;
  line-height: 1.6;
  margin: 0 auto 20px;
  font-size: 22px;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
  .table-scroll table {
    width: 767px;
  }
}

.table-type4 {
  table {
    width: 100%;
    margin: 10px 0;
    border-collapse: collapse;
    border-bottom: 1px solid #d3d3d3;

    thead {
      background-color: #c31926;
      color: #ffffff;
      font-weight: 100;
    }

    thead a {
      background-color: #c31926;
      color: #ffffff;
      font-weight: 100;
    }

    thead a:hover {
      text-decoration: none;
    }

    tbody tr:nth-of-type(even) {
      background-color: #f0f0f0;
    }

    tr {
      border-bottom: 1px solid #d3d3d3;
    }

    tr td:first-of-type(1) {
      text-align: left;
    }

    tr td:nth-child(n + 2) {
      text-align: center;
    }

    th,
    td {
      white-space: nowrap;
      padding: 8px;
      vertical-align: middle;
      border: 1px solid #d3d3d3;
    }

    th {
      text-align: center;
      font-size: 13px;
      font-weight: 100;
    }
  }

  .disabled {
    background-color: #999 !important;
    pointer-events: none;
  }

  input[type='radio'] {
    margin-right: 10px;
  }
}

.table-header-content {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0;
  }

  &__title {
    padding-left: 24px;
    text-decoration: underline;
    text-align: left;
  }
}
</style>
