<template>
  <div class="linking">
    <LoadingComponent v-if="isExecutingOnAccountlinkage" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お客様番号連携</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お客様番号連携</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:UCOM お客様番号連携 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="UCOM光 レジデンス お客様番号連携" />UCOM光 レジデンス お客様番号連携</h2>
        <p>
          <span>UCOM光 レジデンス新規会員登録時にお送りした【インターネット接続サービス登録証】をご確認ください。</span><br />
          <span>お客様番号・お客様番号パスワードをご入力いただき、「UCOM光 レジデンス お客様番号連携」ボタンを押してください。</span><br />
          まだ、UCOM光 レジデンスの会員登録がお済みでない方は、<router-link to="/ucom/entry/input" class="link">UCOM光 レジデンス お客様情報登録ページ</router-link
          >>からお手続きをお願いいたします。<br />
          <span class="red"
            >※UCOM光
            レジデンス新規会員登録時に入居状況で「未入居」を選択いただいた方には、改めて移転予定日の前日に【インターネット接続サービス登録証】メールをお送りいたします。</span
          ><br />
          <span>※アカウントID・パスワードでは連携することはできませんのでご注意ください。</span>
        </p>
        <div class="error-messages-component-container">
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component
            v-bind:errorMessages="errorMessages"
            v-bind:errorMessageTitle="errorMessageTitle"
            v-bind:isIspLinkingStyle="errorMessageTitle === auth0PropertyIdNoMatchErrorMessageTitle ? true : false"
          />
        </div>
        <table class="table-type2">
          <tr>
            <th class="va-middle">お客様番号<span class="req">必須</span></th>
            <td><input type="text" class="text middle" v-model="inputData.loginId" /></td>
          </tr>
          <tr>
            <th class="va-middle">お客様番号パスワード<span class="req">必須</span></th>
            <td><input type="password" class="text middle" v-model="inputData.password" /></td>
          </tr>
        </table>
        <p class="att mt15">
          ※【インターネット接続サービス登録証】を紛失された場合の再発行（郵送）は
          <a class="link" href="https://support.ucom.ne.jp/procedure/reissue/index.php" target="UCOM">こちら</a>。
        </p>
        <p class="note-on-id-and-password">※入力いただいたお客様番号・パスワードはPortasには保持しません。</p>

        <div class="sblc">
          <div class="btn-area">
            <button class="btn btn01 bs" v-on:click="onAccountlinkage">UCOM光 レジデンス お客様番号連携</button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

div.blc {
  p.note-on-id-and-password {
    margin-bottom: 8px;
  }
}

.error-messages-component-container {
  margin-top: 16px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { UcomLinkingInput } from '@/shared/classes/auth-manegement/ucom-linking-input';
import { AuthService } from '@/shared/services/auth/auth-service';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AUTH0_PROPERTY_ID_NO_MATCH } from '@/shared/const/error';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { InitUcomCustomerNoUser } from '@/shared/classes/spf-api/mail/model/ucom/account/init-ucom-customer-no-user';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-accessor';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

/** Linking コンポーネント */
export default defineComponent({
  name: 'linking',
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    auth0PropertyIdNoMatchErrorMessageTitle: AUTH0_PROPERTY_ID_NO_MATCH.displayTitle.UCOM,
    defaultErrorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    errorMessageTitle: '',
    /** 入力データ */
    inputData: {
      loginId: '',
      password: '',
    } as UcomLinkingInput,
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    ucomCustomerRegistrationUrl: process.env.VUE_APP_UCOM_CUSTOMER_REGISTRATION_URL,
  }),
  validations: {
    inputData: {
      loginId: {
        required,
      },
      password: {
        required,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn: boolean = await AuthService.isLoggedIn();

    // ログインしていなければ エラー画面 に遷移する
    if (!isLoggedIn) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });

      return;
    }

    /** 会員テーブルの UCOM の主キーから ユーザが UCOM と連携しているかを確認する */
    const member: Member | null = this.$store.getters['memberStore/member'];
    if (member?.primaryKeyUcom) {
      // 連携していれば UCOM サービストップに遷移する
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.v$.inputData.$invalid) {
        if (this.v$.inputData.loginId?.required.$invalid) {
          this.errorMessages.push('お客様番号を入力してください。');
        }
        if (this.v$.inputData.password?.required.$invalid) {
          this.errorMessages.push('パスワードを入力してください。');
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isExecutingOnAccountlinkage = false;
    },
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        this.isExecutingOnAccountlinkage = true;
        this.errorMessageTitle = this.defaultErrorMessageTitle;

        if (this.validate()) {
          /** UCOM アカウント連携 API を使用して次の 3 つの処理を実行する
           * 1. UCOM 外部 API に入力情報（お客様番号・パスワード）を渡して UCOM 登録済みか否かを確認
           * 2. Portas 会員テーブルの「UCOM の主キー」項目にお客様番号を格納
           * 3. Auth0 のプライマリにセカンダリとして UCOM 会員 ID の紐付けを行う
           */
          const result: undefined | AuthManegementSharedErrorResponse = await AuthManagementApiService.linkingToUcom(this.$data.inputData);
          if (result instanceof AuthManegementSharedErrorResponse) {
            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              this.showErrorMessage();
              return;
            } else if (result.errors.some((value) => value === AUTH0_PROPERTY_ID_NO_MATCH.errorMessage)) {
              this.errorMessageTitle = this.auth0PropertyIdNoMatchErrorMessageTitle;

              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[0]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[1]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[2].replace('REPLACE_URL', process.env.VUE_APP_REPLACE_URL_AUTH0_PROPERTY_ID_NO_MATCH_UCOM));

              this.showErrorMessage();
              return;
            } else if (result.status === 500) {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.AUTH0_PORTAS.UCOM_ACCOUNT_LINKING);
            } else {
              this.errorMessages.push('お客様番号あるいはパスワードが一致しません。');
              this.showErrorMessage();
              return;
            }
          } else {
            await AuthService.refresh();
            // 正常ケースの場合

            // アカウントテーブル作成APIコール
            // エラーハンドリングについては既存踏襲
            const member: Member = await this.$store.getters['memberStore/member'];
            await SpfApiAccountAccessor.insertInitUcomCustomerNoUser(new InitUcomCustomerNoUser(member.id, Number(await AuthService.getUcomMemberId())));

            // AuthManagementApiService.linkingToUcom に成功するとバックエンドで会員ステータスが更新される
            // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
            this.$store.commit('memberStore/memberStatus', null);
            this.$store.commit('memberStore/member', null);

            // UCOM サービストップ に遷移
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await this.$router.push('/ucom').catch((error: any) => {
              checkRouterError(error);
            });
            return;
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
});
</script>
