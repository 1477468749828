<template>
  <div class="confirm">
    <LoadingComponent v-if="isOnPrevExecuting || isSubmitting" />
    <main class="underlayer-main">
      <h1>UCOM光 レジデンス お申し込み 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>UCOM光 レジデンス お申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid3 gap10">
        <li>情報の入力</li>
        <li class="stay">入力内容の確認</li>
        <li>登録完了</li>
      </ul>
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="入力内容の確認" />入力内容の確認</h2>
        <p>入力された内容をご確認の上、「登録」ボタンを押してください。<br />登録内容を入力し直す場合は、「{{ backButtonName }}」ボタンでお戻りください。</p>
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込み者情報</h3>
          <!-- 入力画面での入力内容を取得できるまでは非表示（取得に失敗した場合はエラー画面に遷移） -->
          <table class="table-type2" v-if="entryInput">
            <tr>
              <th>お申し込み年月日</th>
              <td>{{ dispApplydate }}</td>
            </tr>
            <tr>
              <th>お名前</th>
              <td>{{ entryInput.firstName }}&nbsp;&nbsp;{{ entryInput.givenName }}</td>
            </tr>
            <tr>
              <th>お名前フリガナ</th>
              <td>{{ entryInput.firstNameKana }}&nbsp;&nbsp;{{ entryInput.givenNameKana }}</td>
            </tr>
            <tr>
              <th>設置先ご住所</th>
              <!-- データが取得できるまでは非表示 -->
              <td v-if="entryInput.postalCode">
                〒{{ dispZipcode }}<br />
                {{ entryInput.addressPrefectures }}
                {{ entryInput.addressMunicipalities }}
                {{ entryInput.addressTown }}
                {{ entryInput.addressStreet }}
                {{ entryInput.addressNumber }}
                {{ entryInput.sendBuildingName }}
              </td>
            </tr>
            <tr>
              <th>楝番号/部屋番号</th>
              <td>{{ entryInput.roomNumber }}</td>
            </tr>
            <tr>
              <th>お電話番号</th>
              <td>{{ entryInput.phoneNumber }}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{{ entryInput.eMail }}</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>{{ dispBirthdate }}</td>
            </tr>
            <tr>
              <th>入居状況<span class="req">必須</span></th>
              <td v-if="entryInput.tenancyStatus === '1'">入居済</td>
              <td v-if="entryInput.tenancyStatus === '0'">
                未入居
                <span v-if="entryInput.expectedMoveInOn">（移転予定日 {{ dispExpectedMoveInOn }}）</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お支払い情報入力</h3>
          <p>
            お支払い情報を選択してください。
            なお、月額基本料金のある物件および有料サービスをご利用になる場合には、クレジットカード情報の登録が必要です。（クレジットカードを選択すると入力欄が表示されます）
          </p>
          <table class="table-type2">
            <tr>
              <th>お支払い情報<span class="req">必須</span></th>
              <td>
                <label><input type="radio" v-model="paymentMethodId" value="1" :disabled="creditCardToken !== ''" />クレジットカード</label>
                <label><input type="radio" v-model="paymentMethodId" v-on:click="turnOffAgreeCardCopy" value="5" :disabled="creditCardToken !== ''" />未登録</label>
              </td>
            </tr>
          </table>
          <div v-if="paymentMethodId === '1'">
            <credit-card-component
              v-if="isMounted"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:ispProviderCompanyName="ispProviderCompanyName"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:backButtonName="backButtonName"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
            />
          </div>
        </div>
        <!-- 月額基本料金が発生する物件の場合、表示 -->
        <div class="sblc" v-if="entryProperty">
          <template v-if="getNumericPortionOfPrice(entryProperty.monthly_basic_charge) > 0 || getNumericPortionOfPrice(entryProperty.property_initial_cost) > 0">
            <h3 class="service-h3">料金</h3>
            <table class="table-type2">
              <tbody>
                <tr v-if="getNumericPortionOfPrice(entryProperty.monthly_basic_charge) > 0">
                  <th>月額基本料金（税込）</th>
                  <td>{{ entryProperty.monthly_basic_charge }}</td>
                </tr>
                <tr v-if="getNumericPortionOfPrice(entryProperty.property_initial_cost) > 0">
                  <th>物件初期費用（税込）</th>
                  <td>{{ entryProperty.property_initial_cost }}</td>
                </tr>
              </tbody>
            </table>
            <p>IP電話サービスの料金は、UCOM光電話もしくはGATE CALL050(M)の月額基本料金・機器使用料を確認ください。</p>
          </template>
          <!-- IP電話サービスのお申し込みにチェックを入れていた場合に表示 -->
          <div v-if="entryInput && entryInput.isApplyIPTelSercice">
            <h3 class="service-h3">IP電話サービスのお申し込み</h3>
            <table class="table-type2">
              <tr v-if="entryProperty.is_possible_opitcal_phone">
                <th>
                  <p class="font18px mb10">UCOM光電話</p>
                  <p class="font13px">事務手数料 {{ entryProperty.optical_phone_commission }}<br /></p>
                  <p class="font13px">月額基本料金 {{ entryProperty.optical_phone_monthly_basic_charge }}<br /></p>
                  <p class="font13px">月額機器利用料金 {{ entryProperty.optical_phone_monthly_usage_fee }}</p>
                </th>
                <td>
                  <p class="mb10" v-if="entryInput.applyOpticalPhone == '1'">申し込みする</p>
                  <p class="mb10" v-if="entryInput.applyOpticalPhone == '0'">申し込みしない</p>
                  <div v-if="entryInput.applyOpticalPhone == '1'">
                    <p>電話番号取得方法：{{ entryInput.opticalPhoneNumberGetMethodValue }}</p>
                    <!-- 電話番号取得方法が「番号ポータビリティ」の場合のみ表示 -->
                    <div v-if="entryInput.opticalPhoneNumberGetMethodId == OPTICAL_PHONE_NUMBER_GET_METHOD_ID.NUMBER_PORTABILITY">
                      <p>ご利用中の電話会社にご登録の住所 (郵便番号): 〒{{ entryInput.opticalPhonePostalCode }}</p>
                      <p>ご利用中の電話会社にご登録の住所 (番地まで): {{ entryInput.opticalPhoneAddress }}</p>
                      <p>ご利用中の電話会社にご登録の住所 (マンション名など): {{ entryInput.opticalPhoneBuildingName }}</p>
                      <p>引継電話番号 : {{ entryInput.opticalPhoneTakeoverNumber }}</p>
                      <p>
                        名義人区分：
                        <span v-if="entryInput.opticalPhoneHolderCategory == '1'">本人</span>
                        <span v-if="entryInput.opticalPhoneHolderCategory == '2'">本人以外</span>
                      </p>
                      <p v-if="entryInput.opticalPhoneHolderName">ご利用中の電話会社にご登録のお名前：{{ entryInput.opticalPhoneHolderName }}</p>
                      <p v-if="entryInput.opticalPhoneHolderNameKana">ご利用中の電話会社にご登録のお名前カナ：{{ entryInput.opticalPhoneHolderNameKana }}</p>
                      <p v-if="entryInput.opticalPhoneUseCompanyValue">
                        ご利用中の電話会社： {{ entryInput.opticalPhoneUseCompanyValue }}({{ entryInput.opticalPhoneUseCompanyOther }})
                      </p>
                    </div>
                    <table entryInput.opticalPhoneImpossibleServiceId.length>
                      <tr>
                        <p>光電話付加サービス（有料）：</p>
                        <td class="option">
                          <p v-for="itemOpticalPhoneImpossibleService in entryInput.opticalPhoneImpossibleServiceId" :key="itemOpticalPhoneImpossibleService">
                            {{ itemOpticalPhoneImpossibleService }}
                          </p>
                        </td>
                      </tr>
                    </table>
                    <p>
                      番号通知：
                      <span v-if="entryInput.opticalPhoneNumberNotificationId == '1'">通知する</span>
                      <span v-if="entryInput.opticalPhoneNumberNotificationId == '0'">通知しない</span>
                    </p>
                  </div>
                </td>
              </tr>
              <tr v-if="entryProperty.is_possible_gate_call_050">
                <th>
                  <p class="font18px mb10">GATE CALL050(M)</p>
                  <p class="font13px">月額基本料金 {{ entryProperty.gate_call_050_monthly_basic_charge }}</p>
                  <br />
                  <p class="font13px">月額専用アダプタレンタル料 {{ entryProperty.gate_call_050_monthly_adapter_rental_fee }}</p>
                </th>
                <td>
                  <p class="mb10" v-if="entryInput.applyGATECALL050 == '1'">申し込みする</p>
                  <p class="mb10" v-if="entryInput.applyGATECALL050 == '0'">申し込みしない</p>
                </td>
              </tr>
            </table>
            <p class="att grey">
              ※1電話番号ごとにユニバーサルサービス料が別途かかります。ユニバーサルサービス料は変動する場合がございます。詳しくは右記URL（<a
                href="https://www.arteria-net.com/uni/"
                target="_blank"
                class="link"
                >https://www.arteria-net.com/uni/</a
              >）をご覧ください。
            </p>
            <p class="att grey">
              ※1電話番号ごとに電話リレーサービス料が別途かかります。電話リレーサービス料は変動する場合がございます。詳しくは右記URL（<a
                :href="`${ucomUrl}/relay.html`"
                target="_blank"
                class="link"
                >https://ucom-r.ne.jp/relay.html</a
              >）をご覧ください。
            </p>
            <p class="inputUcomIpText">※ IP電話サービスのご利用開始の設定時に、LANポートのあるパソコンが必要となります。ご確認の上、お申し込みください。</p>
          </div>
          <!-- 入力画面でIP電話サービスのお申し込みをしていない場合は、「選択無し」の一文のみを表示 -->
          <div v-else>
            <h3 class="service-h3">IP電話サービスのお申し込み</h3>
            <p>選択なし</p>
          </div>
        </div>
        <div class="sblc">
          <h3 class="service-h3">同意事項のご確認</h3>
          <p>本サービスのお申し込みにあたり、下記事項に同意されたことを確認いたしました。</p>
          <!-- 入力画面での入力内容を取得できるまでは非表示（取得に失敗した場合はエラー画面に遷移） -->
          <table class="table-type2" v-if="entryProperty">
            <tr>
              <th>提供会社</th>
              <!-- サービス提供元企業IDの値によって表示する企業名を変更 -->
              <td>{{ serviceSupplyCompanyName }}</td>
            </tr>
            <tr>
              <th>お申し込みサービス</th>
              <td>
                本サービスは、{{
                  serviceSupplyCompanyName
                }}がお住まいの集合住宅様との契約に基づき提供するサービスです。提供物件にお住まいの方が本サービス利用をお申し込みいただけます。<br />アルテリア・ネットワークス株式会社の光ファイバー回線を利用したシェア型のインターネット接続サービスです。
              </td>
            </tr>
            <tr>
              <th>契約の内容確認、変更または解除の連絡先</th>
              <td>
                <p>&lt;マイページ&gt;</p>
                <p><a class="link" href="https://portas.jp/">https://portas.jp/</a></p>
                <p>&lt;お問い合わせ窓口&gt;</p>
                <p>
                  お問い合わせフォーム、お電話でのお問い合わせ先は、「<a class="link" :href="getBuildingWebsiteUrl()" target="UCOM">UCOM光 レジデンス 建物専用サイト</a
                  >」をご確認ください。
                </p>
              </td>
            </tr>
            <tr>
              <th>「VDSL方式」・「G.Fast方式」のレンタル機器の取り扱い</th>
              <td>
                <p>レンタル機器の返還を行わない場合、毀損、亡失は、違約金が発生します。</p>
                <ul class="ul-basic01">
                  <li>VDSLモデム及びG.Fastモデム：¥8,000</li>
                  <li>UCOM光電話用 専用アダプタ：¥21,000</li>
                  <li>GATE CALL050(M) 専用アダプタ：¥21,000</li>
                  <li>専用アダプタ付属品：¥3,150</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>初期契約解除に関する事項</th>
              <td>
                <ol class="ol-basic01">
                  <li>
                    本サービスは初期契約解除制度の対象です。
                    但し、本サービスの導入につき、入居者ごとの契約ではなく、全戸一括加入方式として建物ごとに一括して契約している場合には対象外となります。
                  </li>
                  <li>
                    登録証の受領日を初日とする8日間が経過するまで、お客様の都合により契約を解除できます。以下の連絡先へお電話いただくか、弊社に書面での通知をしていただくことで、本契約の解除を行うことができます。また、この効力はお電話をいただいた時、書面を発送された時に生じます。<br />※書面通知先：〒105-0004&emsp;東京都港区新橋6-9-8住友不動産新橋ビル&emsp;UCOM光
                    レジデンス登録センター宛
                  </li>
                  <li>
                    この場合、お客様は、①損害賠償若しくは違約金その他金銭等を請求されることはありません。②ただし、本契約の解除までの期間において提供を受けた電気通信役務の料金、事務手数料及び既に工事が実施された場合の工事料は請求されます。③また、本契約に関連して弊社が金銭等を受領している際には、当該金銭等（上記②で請求する金額を除く。）をお客様に返還いたします。
                  </li>
                  <li>
                    弊社、若しくは本サービスの契約を媒介する事業者が初期契約解除制度について不実のことを告げたことにより、お客様が告げられた内容が事実であるとの誤認をし、上記期間を経過するまでに契約を解除しなかった場合、本契約の解除を行うことができる旨を記載して交付した登録証の受領日を初日とする8日間が経過するまで、お客様の都合により契約を解除できます。
                  </li>
                </ol>
              </td>
            </tr>
          </table>
        </div>

        <!-- UCOM光レジデンス登録証の送付確認は Phase1 以降で実装予定 -->
        <!-- <template v-if="entryInput && entryProperty">
          <div class="sblc" v-if="entryProperty.contract_kind_id == '1' || entryProperty.contract_kind_id == '2' || entryProperty.contract_kind_id == '3'">
            <h3 class="service-h3">UCOM光レジデンス登録証のご送付</h3>
            <table class="table-type2">
              <tr>
                <th>登録証の郵送</th>
                <td v-if="entryInput.isSendRegistrationCard">希望する</td>
                <td v-else>希望しない</td>
              </tr>
            </table>
          </div>
        </template> -->

        <div class="sblc">
          <p class="form-btn-txt red">本内容はお客様の契約内容となります。<br />ご登録前に本ページを必ず印刷し、契約終了までお手元に保存してください。</p>
          <div class="btn-area">
            <button type="button" class="btn btn05 bs" v-on:click="onPrev">{{ backButtonName }}<i class="material-icons link link-icon">west</i></button>
            <button type="button" class="btn btn01 bs" v-on:click="onNext" :disabled="isDisabledButton">登録<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style scoped lang="scss">
/* button.btn
---------------------------------------------------- */
button.btn {
  padding: 12px 25px;
  line-height: 24px;
}

/* table-type2 th
---------------------------------------------------- */

/* underlayer-main
---------------------------------------------------- */
.underlayer-main {
  background-image: url('../../../images/ucom/main.png');
}

/* underlayer-main ul
---------------------------------------------------- */
.underlayer-main ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

/* underlayer-main ul li
---------------------------------------------------- */
.underlayer-main ul li {
  display: inline-block;
  margin-right: 20px;
}

/* underlayer-main ul li img
---------------------------------------------------- */
.underlayer-main ul li img {
  height: 40px;
}

/* underlayer-main h1
---------------------------------------------------- */
.underlayer-main h1 {
  font-size: 24px;
  margin: 0;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
}

/* mansion-box
---------------------------------------------------- */
.mansion-box {
  background-color: #fcfcfc;
  padding: 20px 30px;
  margin: 20px 0;
  text-align: center;
}

/* sbtn
---------------------------------------------------- */
.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  color: #cf1225;
  border: 1px solid #cf1225;
  border-radius: 2px;
  margin-top: -40px;
  padding: 0 10px;
  background-color: #fff;
}

.sbtn:hover {
  opacity: 0.7;
}

.fix-btn {
  float: right;
  color: #939393;
  border: 1px solid #939393;
  margin-top: -40px;
}

/* crecitcard
---------------------------------------------------- */
.crecitcard {
  max-width: 100%;
  margin: 10px 0;
}

/* form-btn-txt
---------------------------------------------------- */
.form-btn-txt {
  text-align: center;
}

/* authentication-key
---------------------------------------------------- */
.authentication-key {
  padding: 15px 30px;
  margin: 15px 0 0;
  border: 1px solid #e8e8e8;
  background-color: #fcfcfc;
}

.input {
  margin: 10px 0;
}

.input .text {
  width: 380px;
  margin: -10px 10px 0 0;
}

.input .btn {
  position: relative;
  top: 2px;
}

/* inputUcomIpText
---------------------------------------------------- */
.inputUcomIpText {
  color: #cf1225;
}

@media only screen and (max-width: 767px) {
  /* authentication-key
  ---------------------------------------------------- */
  .authentication-key {
    padding: 15px;
  }

  .input .text {
    width: 42%;
  }

  .input .btn {
    min-width: 22%;
  }
}

.option {
  padding: 0px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

.btn01:disabled {
  opacity: 0.5;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomEntryInputForm } from '@/pages/ucom/entry/classes/entry-input-form';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { LinkServiceWithUcomRequest } from '@/shared/classes/spf-api/link-service-with-ucom';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ProcessesCalledInUcomPages } from '@/shared/util/processes-called-in-ucom-page';
import { OPTICAL_PHONE_NUMBER_GET_METHOD_ID } from '@/shared/const/ucom/optical-phone';

/** UCOM新規会員登録 入力内容の確認画面 */
export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
    /** サービスのお申し込み_UCOM光電話 */
    serviceApplyUcomTel: '',
    /** サービスのお申し込み_GATE CALL 050(M) */
    serviceApplyGateCall050M: '',
    /** 入力画面での入力値 */
    entryInput: null as UcomEntryInputForm | null,
    /** 入力画面で取得した物件情報 */
    entryProperty: null as UcomPropertyResponse | null,
    /** 支払い方法 */
    paymentMethodId: '',
    /** クレジットカードトークン */
    creditCardToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面では UCOM光 レジデンス固定) */
    ispName: 'UCOM光 レジデンス',
    /** UCOMの提供元会社名を登録する。空文字の場合は"株式会社つなぐネットコミュニケーションズ"が表示される */
    ispProviderCompanyName: '',
    /** ユーザーがPortasからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得完了メッセージ */
    successMessage: '画面下の「登録」を押してください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** UCOM光を提供する会社の名前 */
    serviceSupplyCompanyName: '',
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    // onPrev()実行中かどうか
    isOnPrevExecuting: false,
    /** お申し込み年月日（表示用） */
    dispApplydate: '',
    /** 郵便番号（表示用） */
    dispZipcode: '',
    /** 生年月日（表示用） */
    dispBirthdate: '',
    /** 移転予定日（表示用） */
    dispExpectedMoveInOn: '',
    /** UCOM光電話 電話番号取得方法ID 定数 */
    OPTICAL_PHONE_NUMBER_GET_METHOD_ID: OPTICAL_PHONE_NUMBER_GET_METHOD_ID,
    /**  mounted()実行中かどうか */
    isMounted: false as boolean,
    /**  クレジットカードコンポーネントに渡す、本画面における前画面へ遷移するボタンの名称 */
    backButtonName: '修正',
  }),
  async mounted(): Promise<void> {
    const member = this.$store.getters['memberStore/member'];

    // 入力値、 物件基本情報情報取得APIの取得結果
    this.entryInput = this.$store.getters['ucomEntryStore/entryInputForm'];
    this.entryProperty = this.$store.getters['ucomEntryStore/externalApiProperty'];

    if (!this.entryInput || !this.entryProperty) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // サービス提供会社名を設定
    if (this.entryProperty.service_provid_former.service_provid_former_id === '1') {
      this.serviceSupplyCompanyName = '株式会社つなぐネットコミュニケーションズ';
    }

    if (this.entryProperty.service_provid_former.service_provid_former_id === '2') {
      this.serviceSupplyCompanyName = 'アルテリア・ネットワークス株式会社';
    }

    /**
     * APIトークンキーを設定
     * .env.development, .env.staging, .env.production それぞれに次の環境変数を追加
     * 1. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC
     * 2. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE
     */

    // サービス提供企業ID:1（株式会社つなぐネットコミュニケーションズ） => TNCのトークンAPIキーを利用
    if (this.entryProperty.service_provid_former.service_provid_former_id === '1') {
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC;
    }

    // サービス提供企業ID:2（アルテリア・ネットワークス株式会社） => ARTE(大和)のトークンAPIキーを利用
    if (this.entryProperty.service_provid_former.service_provid_former_id === '2') {
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE;
      this.ispProviderCompanyName = 'アルテリア・ネットワークス株式会社';
    }

    /**
     * 支払方法の決定
     *
     * 次の2つの場合は、クレジットカードを選択状態にする
     * ・ /ucom/entry/input にて、IP電話サービス(UCOM光電話 または GATE CALL 050)の申し込みを行っていた場合
     * ・ 月額基本料金が発生する物件の場合
     */
    const isBasicChargePayment = this.entryProperty && this.getNumericPortionOfPrice(this.entryProperty.monthly_basic_charge) > 0 ? true : false;
    this.paymentMethodId = this.entryInput.applyOpticalPhone === '1' || this.entryInput.applyGATECALL050 === '1' || isBasicChargePayment ? '1' : '5';

    // 表示用お申し込み年月日取得
    this.dispApplydate = this.getDispDate(this.entryInput.applyDate);
    // 表示用郵便番号取得
    this.dispZipcode = this.getDispZipcode(this.entryInput.postalCode);
    // 表示用生年月日取得
    this.dispBirthdate = this.getDispDate(this.entryInput.birthdate);
    // 表示用移転予定日取得
    if (this.entryInput.expectedMoveInOn) {
      this.dispExpectedMoveInOn = this.getDispDate(this.entryInput.expectedMoveInOn);
    }

    if (member.veritransAccountId) {
      this.portasVeriTransId = member.veritransAccountId;
    }

    // UCOM同時申込API が 業務エラーで 確認画面 に戻ってきたときエラーメッセージを表示する
    if (this.$store.getters['ucomEntryStore/hasOccurredBusinessErrorAtLinkServiceWithUcom']) {
      this.errorMessageTitle = 'お支払い方法登録で問題が発生しました。';
      // クレカコピーのとき
      if (this.$store.getters['ucomEntryStore/requestedWithAgreeToCreditCardCopy']) {
        this.errorMessages.push(
          [
            '恐れ入りますがクレジットカードのご利用状況を確認の上、再度お試しいただきますようお願いいたします。',
            `繰り返しエラーが発生する場合、<a class="link" href="${this.getBuildingWebsiteUrl()}" target="UCOM">こちら</a>からサポートセンターへお問い合わせください。`,
          ].join('')
        );
      } else {
        this.errorMessages.push(
          [
            '恐れ入りますが、入力されたクレジットカード詳細情報（クレジットカード番号・有効期限・カード名義人・セキュリティコード）をお確かめのうえ、再度お試しいただくか、別のクレジットカードでお試しください。',
            `繰り返しエラーが発生する場合、<a class="link" href="${this.getBuildingWebsiteUrl()}" target="UCOM">こちら</a>からサポートセンターへお問い合わせください。`,
          ].join('')
        );
      }
      window.scrollTo(0, 0);
    }

    this.isMounted = true;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    /**
     * 「UCOM光 レジデンス 建物専用サイト」のURL
     */
    getBuildingWebsiteUrl(): string {
      return ProcessesCalledInUcomPages.getBuildingWebsiteUrl(this.ucomUrl, this.$store.getters['propertyStore/property']);
    },
    getNumericPortionOfPrice(priceString: string): number {
      return ProcessesCalledInUcomPages.getNumericPortionOfPrice(priceString);
    },
    async onPrev() {
      if (this.isOnPrevExecuting) {
        return;
      }
      this.isOnPrevExecuting = true;

      await this.$router
        .push('/ucom/entry/input')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isOnPrevExecuting = false;
        });
    },
    /** 「登録」ボタン押下時 : 登録完了画面に遷移する */
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      if (this.paymentMethodId === '1') {
        if (!this.creditCardToken && !this.isAgreedCopyCardToIsp) {
          // 支払い方法がクレジットカードで、かつAPIキーが発行されていない場合はエラー
          this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
          this.showErrorMessage();
          return;
        }
      }

      /**
       * 次の3つの場合は、クレジットカード情報の登録が必須
       * 1. 情報入力画面でIP電話サービス(UCOM光電話)を申し込んでいるとき
       * 2. 情報入力画面でIP電話サービス(GATE CALL050(M))を申し込んでいるとき
       * 3. 月額基本料金が発生する物件のとき(0円のときがあるのでチェック)
       */
      const price = this.entryProperty?.monthly_basic_charge ? this.getNumericPortionOfPrice(this.entryProperty?.monthly_basic_charge) : 0;

      const isApplyOpticalPhone = this.entryInput?.isApplyIPTelSercice && this.entryInput.applyOpticalPhone === '1';
      const isApplyGateCall050 = this.entryInput?.isApplyIPTelSercice && this.entryInput.applyGATECALL050 === '1';
      const canRegisterCardInfo = this.creditCardToken || this.isAgreedCopyCardToIsp;

      if (this.paymentMethodId === '5') {
        if (isApplyOpticalPhone || isApplyGateCall050 || price > 0) {
          if (!canRegisterCardInfo) {
            this.errorMessages.push('未登録の選択はできません。お支払い情報を入力してください。');
            this.showErrorMessage();
            return;
          }
        }
      }

      if (!this.entryInput) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/ucom/entry/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOM同時お申し込みAPIに渡すリクエストボディを作成
      const linkServiceWithUcomRequest = new LinkServiceWithUcomRequest({
        applyDate: this.entryInput.applyDate,
        firstName: this.entryInput.firstName,
        givenName: this.entryInput.givenName,
        firstNameKana: this.entryInput.firstNameKana,
        givenNameKana: this.entryInput.givenNameKana,
        postalCode: this.entryInput.postalCode,
        addressPrefectures: this.entryInput.addressPrefectures,
        addressMunicipalities: this.entryInput.addressMunicipalities,
        addressTown: this.entryInput.addressTown,
        addressStreet: this.entryInput.addressStreet,
        addressNumber: this.entryInput.addressNumber,
        sendBuildingName: this.entryInput.sendBuildingName,
        roomNumber: this.entryInput.roomNumber,
        eMail: this.entryInput.eMail,
        phoneNumber: this.entryInput.phoneNumber,
        birthday: this.entryInput.birthdate,
        tenancyStatus: this.entryInput.tenancyStatus,
        expectedMoveInOn: this.entryInput.expectedMoveInOn ? this.entryInput.expectedMoveInOn.replaceAll('-', '/') : undefined,
        paymentMethodId: this.paymentMethodId,
        creditCardToken: this.creditCardToken ? this.creditCardToken : undefined,
        cardAccountId: this.isAgreedCopyCardToIsp && this.portasVeriTransId ? this.portasVeriTransId : undefined,
        isApplyOpticalPhone: this.entryInput.applyOpticalPhone,
        opticalPhoneNumberGetMethodId: this.entryInput.opticalPhoneNumberGetMethodId ? this.entryInput.opticalPhoneNumberGetMethodId : undefined,
        opticalPhonePostalCode: this.entryInput.opticalPhonePostalCode ? this.entryInput.opticalPhonePostalCode : undefined,
        opticalPhoneAddress: this.entryInput.opticalPhoneAddress ? this.entryInput.opticalPhoneAddress : undefined,
        opticalPhoneBuildingName: this.entryInput.opticalPhoneBuildingName ? this.entryInput.opticalPhoneBuildingName : undefined,
        opticalPhoneTakeoverNumber: this.entryInput.opticalPhoneTakeoverNumber ? this.entryInput.opticalPhoneTakeoverNumber : undefined,
        opticalPhoneHolderCategory: this.entryInput.opticalPhoneHolderCategory ? this.entryInput.opticalPhoneHolderCategory : undefined,
        opticalPhoneHolderName: this.entryInput.opticalPhoneHolderName ? this.entryInput.opticalPhoneHolderName : undefined,
        opticalPhoneHolderNameKana: this.entryInput.opticalPhoneHolderNameKana ? this.entryInput.opticalPhoneHolderNameKana : undefined,
        opticalPhoneUseCompany: this.entryInput.opticalPhoneUseCompany ? this.entryInput.opticalPhoneUseCompany : undefined,
        opticalPhoneUseCompanyOther: this.entryInput.opticalPhoneUseCompanyOther ? this.entryInput.opticalPhoneUseCompanyOther : undefined,
        opticalPhoneImpossibleServices: this.entryInput.opticalPhoneImpossibleService,
        opticalPhoneNumberNotificationId: this.entryInput.opticalPhoneNumberNotificationId,
        isApplyGateCall_050: this.entryInput.applyGATECALL050,
        isSendRegistrationCard: '1', // 申し込むで固定
        propertyId: this.entryInput.propertyId,
      });

      // リクエストをストアに保存
      this.$store.commit('ucomEntryStore/linkServiceWithUcomRequest', linkServiceWithUcomRequest);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/entry/during-registration-process').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardToken = token;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せる為に画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      try {
        this.creditTokenExpireDate = creditTokenExpireDate;
      } catch (error) {
        // エラー画面に遷移
        this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        }); // 使用していない画面のためそのまま
      }
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** 表示用郵便番号を取得する */
    getDispZipcode(zipcode: string) {
      if (zipcode.indexOf('-') > -1) {
        return zipcode;
      }
      return zipcode.slice(0, 3) + '-' + zipcode.slice(3);
    },
    /** 表示用日付を取得する */
    getDispDate(date: string) {
      return date.slice(0, 4) + '年' + date.slice(5, 7) + '月' + date.slice(8) + '日';
    },
    /** クレジットカードコンポーネントの非表示時、カードコピーへの同意を解除する */
    turnOffAgreeCardCopy() {
      this.isAgreedCopyCardToIsp = false;
    },
  },
  computed: {
    isDisabledButton(): boolean {
      const isNotApplyOpticalPhone = this.entryInput?.applyOpticalPhone == null || this.entryInput?.applyOpticalPhone === '0';
      const isNotApplyGateCall050 = this.entryInput?.applyGATECALL050 == null || this.entryInput?.applyGATECALL050 === '0';

      if (this.paymentMethodId === '5') {
        if (
          !this.entryProperty?.monthly_basic_charge ||
          (this.getNumericPortionOfPrice(this.entryProperty?.monthly_basic_charge) === 0 && isNotApplyOpticalPhone && isNotApplyGateCall050)
        ) {
          return false;
        }
      }

      if (this.paymentMethodId === '1') {
        if (this.creditCardToken) {
          return false;
        }
        if (!this.isExpiredPortasCreditCard && this.isAgreedCopyCardToIsp) {
          return false;
        }
      }

      return true;
    },
  },
});
</script>
