<template>
  <section class="my-portas-service-description-at-platform-entry-confirm">
    <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="Portasサービスお申し込み内容のご確認" />Portasサービスお申し込み内容のご確認</h2>

    <section class="my-portas-service-description">
      <h2 class="underlayer-h2 my-portas-service-description__title">あんしんサポートセットプラス</h2>
      <p>
        ノートン&trade; セキュリティ、Internet SagiWall for マルチデバイス、Wi-Fi&Device Support 、AdGuardがセットになったお得なサービスです。<br />
        「ノートン&trade; セキュリティ ＋ Internet SagiWall for マルチデバイス ＋ Wi-Fi&Device Support ＋
        AdGuard」のサービス内容、規約、お支払い方法をご確認のうえ、お申し込みください。
      </p>

      <div class="my-portas-service-list-outer">
        <ul class="my-portas-service-list">
          <div class="my-portas-service">
            <div class="title-and-price">
              <h4 class="my-title">商品について</h4>
            </div>
            <div class="description-and-terms">
              <p>各サービスにおいては、以下の利用規約が適用されます。</p>
              <p><a class="link" href="https://www.arteria-net.com/portas/service/terms/marge/setplus.pdf" target="_blank">ご利用規約</a></p>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">利用方法</h4>
              </div>

              <div class="description-and-terms">
                <p>ソフトウェアのダウンロード、インストールが必要なサービスがございます。</p>
                <p>詳細は以下のリンクからご覧ください。</p>
                <p>
                  <a
                    class="link"
                    href="https://www.arteria-net.com/portas/service/setplus?utm_source=Portas&utm_medium=confirmation&utm_campaign=setplus&utm_content=confirmation_portop"
                    target="_blank"
                    >詳細情報</a
                  >
                </p>
                <p>なお、本サービスお申し込み後は、ソフトウェアのダウンロード、インストールの有無にかかわらず料金が発生いたします。</p>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">お支払い方法</h4>
              </div>
              <div class="description-and-terms">
                <p>クレジットカードによるお支払いのみ承っております。</p>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">お支払い時期</h4>
              </div>

              <div class="description-and-terms">
                <p>上記商品は、月額制のサブスクリプションサービスです。</p>
                <p>月額料金は、お申し込み月から解約された月まで発生します。</p>
                <p>日割り精算はございません。</p>
                <p>但し、初めてお申し込みをされた契約者様においては、下記の初回無料期間が適用されます。</p>
                <p>無料期間終了後は、自動的に月額料金が発生します。</p>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">初回無料期間</h4>
              </div>
              <div class="description-and-terms">
                <p>・ノートン&trade; セキュリティ：お申し込み月を含む3ヵ月間</p>
                <p>・Internet SagiWall for マルチデバイス：お申し込み月を含む3ヵ月間</p>
                <p>・Wi-Fi&Device Support：お申し込み月を含む3ヵ月間</p>
                <p>・AdGuard：お申し込み月を含む2ヵ月間</p>
                <p>2024年10月31日（木）以前のお申し込みにつきましては、お申し込み月を含め、3ヵ月間の無料期間が適用されます。</p>
                <p>2024年11月1日（金）以降のお申し込みにつきましては、お申し込み月を含め、2ヵ月間の無料期間が適用されます。</p>
                <a :href="portasServiceFreePeriodChangeNoticeUrl" target="_blank" class="red link"><b>【重要】Portasサービスの無料期間変更のお知らせ</b></a>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">契約期間</h4>
              </div>
              <div class="description-and-terms">
                <p>契約期間の定めはございません。</p>
                <p>お申し込み後、解約された月の末日までを契約期間とします。</p>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">解約方法</h4>
              </div>
              <div class="description-and-terms">
                <p>マイページから解約手続きを行ってください。</p>
                <p>サービス申し込み後、それぞれのサービスについて、いつでも解約手続きができます。</p>
                <p>ご解約の際は、サービス終了日の翌日以降速やかに、ご解約されたサービスのソフトをアンインストールしてください。</p>
              </div>
            </div>
          </div>

          <div class="blc precautions">
            <div class="my-portas-service">
              <div class="title-and-price">
                <h4 class="my-title">ご契約のキャンセルについて</h4>
              </div>
              <div class="description-and-terms">
                <p>対象サービスの性質に鑑み、お申し込み後のキャンセル・返金はお受けできません。</p>
                <p>解約を行う場合、上記解約方法に定める方法にて手続きを行ってください。</p>
              </div>
            </div>
          </div>
        </ul>

        <PortasServicePriceListAndNotes v-if="isMounted" v-bind:productsDtoList="[...productList]" />
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.my-portas-service-description-at-platform-entry-confirm {
  padding-top: 16px;
  & h2 {
    margin: 0;
    margin-bottom: 16px;
  }
}

.my-portas-service-description {
  padding-top: 8px;
  &__title {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.my-portas-service-list-outer {
  margin: 32px 0;
}
.my-portas-service-list {
  display: block;
  margin: 0;
  padding: 0 0 24px 0;
  list-style: none;

  border-bottom: 1px solid #d2d2d2;
  &__item {
    display: block;
    padding: 24px 0;
    border-bottom: 1px solid #d2d2d2;
  }
}

.my-portas-service {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  & div.title-and-price {
    width: 30%;
    font-weight: 400;
    margin-bottom: 8px;
    & div.my-price {
      & ul {
        padding-left: 16px;
        list-style-type: none;
      }
    }
    & p {
      line-height: 1.6;
      letter-spacing: 0.2px;
    }
  }
  & div.description-and-terms {
    font-weight: 400;
    width: 70%;
  }
}
h4.my-title {
  margin: 0;
  margin-bottom: 16px;
  font-size: 15px;
}
.table-type3 {
  width: 65%;
  border-collapse: collapse;
}

.table-type3 th,
.table-type3 td {
  border: 1px solid #000;
  padding: 8px;
}
.table-type3 td:not(:first-child) {
  text-align: right;
}

.table-type3 th {
  background-color: #cf1225;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .my-portas-service-description {
    &__title {
      font-size: 18px;
    }
  }
  .my-portas-service {
    display: block;
    & div.title-and-price {
      width: 100%;
      max-width: 304px;
      margin-bottom: 0;
    }
    & div.description-and-terms {
      width: 100%;
    }
  }
  .table-type3 {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import PortasServicePriceListAndNotes from '@/pages/platform/components/portas-subscription/portas-service-price-list-and-notes.vue';
import { ProductsDto } from '@/shared/classes/spf-api/portas-subscription/products-dto';
import { PRODUCT_ID_WITH_SPECIFIC_USE } from '@/shared/const/portas-subscription/product-id-with-specific-use';
import { SpfApiService } from '@/shared/services/api/spf-api-service';

export default defineComponent({
  name: 'portas-service-description-at-platform-entry-confirm',
  components: {
    PortasServicePriceListAndNotes,
  },
  data: () => ({
    isMounted: false,
    /** 商品情報配列 */
    productList: [] as ProductsDto[],
    /** 【2024年10月31日まで】Portasサービス 無料期間変更のお知らせPDF URL */
    portasServiceFreePeriodChangeNoticeUrl: process.env.VUE_APP_PORTAS_SERVICE_FREE_PERIOD_CHANGE_NOTICE_URL,
  }),
  async mounted(): Promise<void> {
    for (const productId of PRODUCT_ID_WITH_SPECIFIC_USE.USE_IN_PORTAS_ENTRY) {
      const response = await SpfApiService.getProductDetail(productId);
      this.productList.push(response);
    }
    this.isMounted = true;
  },
});
</script>
